<template>
  <v-form
    v-if="occurrenceId"
    ref="form"
    v-model="valid"
    :class="$vuetify.breakpoint.md ? 'mt-8': 'mt-0'"
  >
    <v-container
      fluid
      style="max-width: 1200px"
    >
      <v-col dense>
        <v-row class="d-flex">
          <v-col cols="6">
            <v-row class="align-center mt-2">
              <div class="subtitle-1 font-weight-medium textBlack--text">
                {{ $t('deconve.occurrenceStatus') }}
              </div>
            </v-row>
            <v-row class="align-center mt-4">
              <div
                v-if="occurrenceStatus"
                class="mb-4 subtitle-2 font-weight-regular textBlack--text"
              >
                {{ occurrenceStatus }}
              </div>
              <v-skeleton-loader
                v-else
                style="flex: 1"
                type="text"
                max-width="50%"
              />
            </v-row>

            <v-row class="align-center mt-6">
              <div class="subtitle-1 font-weight-medium textBlack--text">
                {{ $t('deconve.occurrenceValueOfPrevention') }}
              </div>
            </v-row>
            <v-row class="align-center mt-4">
              <div
                v-if="occurrenceValueOfPrevention !== undefined"
                class="mb-4 subtitle-2 font-weight-regular textBlack--text"
              >
                {{ $t('deconve.occurrenceValueOfPreventionInSign',
                      [occurrenceValueOfPrevention]) }}
              </div>
              <v-skeleton-loader
                v-else
                style="flex: 1"
                type="text"
                max-width="50%"
              />
            </v-row>

            <v-row class="align-center mt-6">
              <div class="subtitle-1 font-weight-medium textBlack--text">
                {{ $t('deconve.occurrenceValueOfLoss') }}
              </div>
            </v-row>
            <v-row class="align-center mt-4">
              <div
                v-if="occurrenceValueOfLoss !== undefined"
                class="mb-4 subtitle-2 font-weight-regular warn--text"
              >
                {{ $t('deconve.occurrenceValueOfLossInSign',
                      [occurrenceValueOfLoss]) }}
              </div>
              <v-skeleton-loader
                v-else
                style="flex: 1"
                type="text"
                max-width="50%"
              />
            </v-row>

            <v-row class="align-center mt-6">
              <v-checkbox
                v-if="occurrenceCopsWereCalled !== undefined"
                v-model="occurrenceCopsWereCalled"
                readonly
                class="disablePointer pa-0 ma-0"
                :label="$t('deconve.policeWereCalled')"
              />
              <v-skeleton-loader
                v-else
                style="flex: 1"
                type="text"
                max-width="50%"
              />
            </v-row>

            <v-row class="align-center mt-4">
              <v-checkbox
                v-if="occurrenceThereWasAnArrest !== undefined"
                v-model="occurrenceThereWasAnArrest"
                readonly
                class="disablePointer pa-0 ma-0"
                :label="$t('deconve.thereWereAnArrest')"
              />
              <v-skeleton-loader
                v-else
                style="flex: 1"
                type="text"
                max-width="50%"
              />
            </v-row>

            <v-row class="align-center mt-4 mb-2">
              <div class="subtitle-1 font-weight-medium textBlack--text">
                {{ $t('deconve.occurrenceFiles') }}
              </div>
            </v-row>
            <v-row
              v-if="files"
              class="align-center mt-4 mb-1"
            >
              <div
                v-if="files.length > 0"
                class="d-flex flex-wrap mt-2"
              >
                <file-item
                  v-for="file in files"
                  :key="file.name"
                  :name="file.name"
                  class="pa-1"
                  @open="download(file.url)"
                />
              </div>
            </v-row>
            <v-row
              v-else
              class="mb-2 subtitle-2 font-weight-regular textBlack--text"
            >
              {{ $t('deconve.noOccurrenceFiles') }}
            </v-row>
          </v-col>
        </v-row>

        <v-row class="align-center mt-6">
          <div class="subtitle-1 font-weight-medium textBlack--text">
            {{ $t('deconve.about') }}
          </div>
        </v-row>
        <v-row class="align-center mt-4">
          <v-textarea
            v-model="occurrenceAbout"
            disabled
            class="disablePointer body-2 text-sm-body-1 mb-6"
            height="100"
            no-resize
            outlined
          />
        </v-row>

        <span
          class="mt-4 unreviewed"
          style="display: block; width: 100%; border-top: 1px solid"
        />

        <v-row class="align-center mt-6">
          <v-checkbox
            v-if="isOccurrenceFinished !== undefined"
            v-model="isOccurrenceFinished"
            readonly
            class="disablePointer pa-0 ma-0"
            :label="$t('deconve.occurrenceFinished')"
          />
          <v-skeleton-loader
            v-else
            style="flex: 1"
            type="text"
            max-width="50%"
          />
        </v-row>

        <v-row class="d-flex justify-end">
          <v-btn
            color="neutral"
            outlined
            :height="$vuetify.breakpoint.mobile? '36px' : '40px'"
            elevation="0"
            class="d-flex custom-transform-class text-none px-3"
            :x-small="$vuetify.breakpoint.mobile"
            @click="seeDetails"
          >
            {{ $t('deconve.seeDetails') }}
          </v-btn>
        </v-row>
      </v-col>
    </v-container>
  </v-form>

  <v-row
    v-else
    class="mb-2 mt-6 d-flex align-center"
  >
    <v-icon
      class="mx-4"
      color="textBlack"
      :size="$vuetify.breakpoint.mobile ? 20 : 24"
    >
      {{ icons.occurrenceeIcon }}
    </v-icon>
    <div
      class="subtitle-1 text-sm-h6 font-weight-bold textBlack--text"
    >
      {{ $t('deconve.withoutOccurrence') }}
    </div>
  </v-row>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mdiFolderAccount } from '@mdi/js';
import { OCCURRENCE_THEFT_STATUS } from '@/utils/faceidFilters';
import { mapActions } from 'vuex';

export default {
  name: 'Occurrences',
  props: {
    occurrenceId: { type: String, default: undefined },
  },
  data() {
    return {
      occurrence: undefined,
      valid: true,
      icons: {
        occurrenceeIcon: mdiFolderAccount,
      },
    };
  },
  computed: {
    occurrenceStatusInfo() {
      return this.occurrence?.theft_status;
    },
    occurrenceStatus() {
      const occurrenceStatus = this.occurrenceStatusInfo;

      switch (occurrenceStatus) {
        case OCCURRENCE_THEFT_STATUS.stole:
          return this.$t('deconve.stole');
        case OCCURRENCE_THEFT_STATUS.triedToSteal:
          return this.$t('deconve.triedToStealButWasStopped');
        case OCCURRENCE_THEFT_STATUS.didNotTryToSteal:
          return this.$t('deconve.withoutAlteration');
        case null:
          return this.$t('deconve.noOccurrenceStatus');
        default:
          return undefined;
      }
    },
    occurrenceValueOfPrevention() {
      return this.occurrence?.value_of_prevention;
    },
    occurrenceValueOfLoss() {
      return this.occurrence?.value_of_loss;
    },
    occurrenceCopsWereCalled() {
      return this.occurrence?.cops_were_called;
    },
    occurrenceThereWasAnArrest() {
      return this.occurrence?.there_was_an_arrest;
    },
    occurrenceAbout() {
      return this.occurrence?.about;
    },
    isOccurrenceFinished() {
      return this.occurrence?.is_finished;
    },
    files() {
      return this.occurrence?.files || [];
    },
  },
  created() {
    this.getOccurrenceById();
  },
  methods: {
    ...mapActions({
      fetchOccurrence: 'faceid/fetchOccurrence',
    }),
    getOccurrenceById() {
      this.occurrence = undefined;

      this.fetchOccurrence(this.occurrenceId).then((occurrenceData) => {
        if (occurrenceData) {
          this.occurrence = occurrenceData;
        }
      });
    },
    download(fileUrl) {
      if (fileUrl) {
        window.open(fileUrl);
      }
    },
    seeDetails() {
      return this.$router.push({ name: 'faceid-occurrence', params: { occurrenceId: this.occurrenceId } });
    },
  },
};
</script>
