// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { DATE_TIME_OPTIONS, getDateAndTimeRange } from './dateTimeRange';

export const UNIT_TYPE = {
  reference: 'reference',
  others: 'others',
};

export interface MonitoringUnitsFilter {
  skip: number;
  limit: number;
  unitType: string;
}

interface BaseProfileFilterOptions {
  search: string;
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortOrder: string;
  tags: string[];
  createdAfter?: string;
  createdBefore?: string;
  createdDateOption: string;
  workspaceId?: string;
  workspaceName?: string;
  unitId: string;
  unitName?: string;
}

export interface PeopleFilterOptions extends BaseProfileFilterOptions {
  status: string;
  fileStatus: string;
  videoStatus: string;
  sharingStatus?: string;
  noTags: boolean;
  noUnit: boolean;
  hasFalseNegative: boolean;
}

export interface SuperProfileFilterOptions extends BaseProfileFilterOptions{
  status: string;
  hasSimilar: string;
  reviewStatus: string;
}

export interface DefaultRelatedNotificationsFilter {
  reviewStatus: string;
  search: string;
  createdAfter: string | undefined;
  createdBefore: string | undefined;
  createdDateOption: string;
}

export const SORT_ORDER = {
  ascending: 'ascending',
  descending: 'descending',
};

export const NOTIFICATION_REVIEW_STATUS = {
  all: 'all',
  yes: 'yes',
  no: 'no',
  unreviewed: 'unreviewed',
};

export const NOTIFICATION_STATUS = {
  all: 'all',
  activated: 'activated',
  inTrash: 'inTrash',
};

export const PERSON_FILE_STATUS = {
  all: 'all',
  withFiles: 'withFiles',
  withoutFiles: 'withoutFiles',
};

export const PERSON_VIDEO_STATUS = {
  all: 'all',
  withVideos: 'withVideos',
  withoutVideos: 'withoutVideos',
};

// The options are the same for person and notification status. But let's do it using this to
// improve code style.
export const SUPER_PROFILE_STATUS = NOTIFICATION_STATUS;

export const PERSON_STATUS = {
  all: 'all',
  enabled: 'enabled',
  disabled: 'disabled',
  inTrash: 'in_trash',
};

export const SHARED_PERSON_STATUS = {
  all: 'all',
  approved: 'approved',
  rejected: 'rejected',
  waiting: 'waiting',
};

export const OCCURRENCE_THEFT_STATUS = {
  all: 'all',
  stole: 'stole',
  triedToSteal: 'tried_to_steal',
  withoutAlteration: 'did_not_try_to_steal',
  notFilled: 'not_filled',
};

export const OCCURRENCE_STATUS = {
  all: 'all',
  activated: 'activated',
  inTrash: 'inTrash',
};

export const REVIEW_STATUS = {
  all: 'all',
  waiting: 'waiting',
  rejected: 'rejected',
  approved: 'approved',
};

export const SIMILAR_PROFILES_STATUS = {
  all: 'all',
  withSimilarProfiles: 'withSimilarProfiles',
  noSimilarProfiles: 'noSimilarProfiles',
};

export function similarProfilesStatusToBoolean(status: string): boolean | undefined {
  switch (status) {
    case SIMILAR_PROFILES_STATUS.withSimilarProfiles:
      return true;
    case SIMILAR_PROFILES_STATUS.noSimilarProfiles:
      return false;
    default:
      return undefined;
  }
}

export function booleanToSimilarProfilesStatus(status: boolean): string {
  switch (status) {
    case undefined:
      return SIMILAR_PROFILES_STATUS.all;
    case true:
      return SIMILAR_PROFILES_STATUS.withSimilarProfiles;
    default:
      return SIMILAR_PROFILES_STATUS.noSimilarProfiles;
  }
}

export function superProfileStatusToBoolean(status: string): boolean | undefined {
  switch (status) {
    case SUPER_PROFILE_STATUS.inTrash:
      return true;
    case SUPER_PROFILE_STATUS.activated:
      return false;
    default:
      return undefined;
  }
}

export function booleanToSuperProfileStatus(status: boolean | undefined): string {
  switch (status) {
    case undefined:
      return SUPER_PROFILE_STATUS.all;
    case true:
      return SUPER_PROFILE_STATUS.inTrash;
    default:
      return SUPER_PROFILE_STATUS.activated;
  }
}

export function personFileStatusToBoolean(status: string): boolean | undefined {
  switch (status) {
    case PERSON_FILE_STATUS.withFiles:
      return true;
    case PERSON_FILE_STATUS.withoutFiles:
      return false;
    default:
      return undefined;
  }
}

export function booleanToPersonFileStatus(status: boolean | undefined): string {
  switch (status) {
    case undefined:
      return PERSON_FILE_STATUS.all;
    case true:
      return PERSON_FILE_STATUS.withFiles;
    default:
      return PERSON_FILE_STATUS.withoutFiles;
  }
}

export function personVideoStatusToBoolean(status: string): boolean | undefined {
  switch (status) {
    case PERSON_VIDEO_STATUS.withVideos:
      return true;
    case PERSON_VIDEO_STATUS.withoutVideos:
      return false;
    default:
      return undefined;
  }
}

export function booleanToPersonVideoStatus(status: boolean | undefined): string {
  switch (status) {
    case undefined:
      return PERSON_VIDEO_STATUS.all;
    case true:
      return PERSON_VIDEO_STATUS.withVideos;
    default:
      return PERSON_VIDEO_STATUS.withoutVideos;
  }
}

function baseDefaultProfileFilterOptions(): BaseProfileFilterOptions {
  const createdDateOption = DATE_TIME_OPTIONS.anyPeriod;
  const {
    startDate: createdAfter, endDate: createdBefore,
  } = getDateAndTimeRange(createdDateOption);

  return {
    search: '',
    page: 1,
    itemsPerPage: 8,
    sortBy: 'created_at',
    sortOrder: SORT_ORDER.descending,
    tags: [],
    createdAfter,
    createdBefore,
    createdDateOption,
    unitId: '',
    unitName: undefined,
  };
}

export function defaultPeopleFilterOptions(): PeopleFilterOptions {
  const defaultPeopleBaseFilters = baseDefaultProfileFilterOptions();

  return {
    ...defaultPeopleBaseFilters,
    workspaceId: undefined,
    workspaceName: undefined,
    status: PERSON_STATUS.all,
    noTags: false,
    fileStatus: PERSON_FILE_STATUS.all,
    videoStatus: PERSON_VIDEO_STATUS.all,
    sharingStatus: undefined,
    noUnit: false,
    hasFalseNegative: false,
  };
}

export function defaultSuperProfileFilterOptions(): SuperProfileFilterOptions {
  const defaultSuperProfileBaseFilters = baseDefaultProfileFilterOptions();

  return {
    ...defaultSuperProfileBaseFilters,
    status: SUPER_PROFILE_STATUS.activated,
    hasSimilar: SIMILAR_PROFILES_STATUS.all,
    reviewStatus: REVIEW_STATUS.all,
  };
}

export function defaultMonitoringFilterOptions(): MonitoringUnitsFilter {
  return {
    skip: 0,
    limit: 8,
    unitType: UNIT_TYPE.reference,
  };
}

export function defaultRelatedNotificationsFilterOptions(): DefaultRelatedNotificationsFilter {
  const createdDateOption = DATE_TIME_OPTIONS.anyPeriod;
  const {
    startDate: createdAfter, endDate: createdBefore,
  } = getDateAndTimeRange(createdDateOption);

  return {
    reviewStatus: NOTIFICATION_REVIEW_STATUS.yes,
    search: '',
    createdAfter,
    createdBefore,
    createdDateOption,
  };
}
