












































import Vue from 'vue';

import { mapActions, mapGetters } from 'vuex';
import { mdiClose } from '@mdi/js';
import { getRingtoneFileName } from '@/utils/ringtone';
import { types } from '../store/socket/types';
import { Notification } from '../store/faceid/notifications/types';

interface Data {
  showNotification: boolean;
  audio?: HTMLAudioElement;
  notificationIds: string[];
  timer?: number;
  currentIndex: number;
  intervalTimeoutMs: number;
  isAudioSoundDisabled: boolean;
  icons: Record<string, string>;
}

export default Vue.extend({
  name: 'Notifier',
  data(): Data {
    return {
      showNotification: false,
      audio: undefined,
      notificationIds: [],
      timer: undefined,
      currentIndex: 0,
      intervalTimeoutMs: 5000,
      isAudioSoundDisabled: false,
      icons: {
        close: mdiClose,
      },
    };
  },
  computed: {
    ...mapGetters({
      event: 'getSocketEvent',
      isNotificationEnabled: 'isNotificationEnabled',
      myWorkspace: 'workspace/myWorkspace',
      isDemoMode: 'isDemoMode',
    }),
    workspaceRingtone(): string {
      const { ringtone } = this.myWorkspace;
      const ringtoneFileName = getRingtoneFileName(ringtone);

      return `/sounds/${ringtoneFileName}`;
    },
    isMobileVersion(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },
    numberOfNotifications(): number {
      return this.notificationIds.length;
    },
  },
  watch: {
    event: 'onNewEvent',
    myWorkspace: 'setAudio',
    numberOfNotifications(value: number) {
      if (value === 0) this.close();
    },
  },
  beforeDestroy() {
    window.clearInterval(this.timer);
  },
  created() {
    this.setAudio();

    if (this.isDemoMode) {
      setTimeout(() => {
        this.addNotification('00000000-0000-0000-0000-000000000000');
      }, 5000);
    }
  },
  methods: {
    ...mapActions({
      fetchNotification: 'faceid/fetchNotification',
    }),
    seeNotification() {
      const [notificationId] = this.notificationIds;

      if (notificationId) {
        this.removeNotification(notificationId);
        this.$router.push({ name: 'faceid-notification', params: { notificationId } });
      }
    },
    close() {
      this.showNotification = false;

      if (this.audio) {
        // Stop audio and reset time
        this.audio.pause();
        this.audio.currentTime = 0;
        window.clearInterval(this.timer);
      }
    },
    addNotification(notificationId: string): void {
      // TODO: this is a temporary solution to avoid to show the alert card for user with tags
      // The correct solution is to implement this in the websocket service
      this.fetchNotification(notificationId).then((notification: Notification | undefined) => {
        if (notification) {
          this.notificationIds.push(notificationId);

          if (this.isNotificationEnabled) {
            this.showNotification = true;
            this.playAudio();
          }
        }
      }).catch(() => {
        // User does not have access to the notification
      });
    },
    removeNotification(notificationId: string): void {
      const notificationIndex = this.notificationIds.indexOf(notificationId);
      const notificationExists = notificationIndex !== -1;

      if (notificationExists) {
        this.notificationIds.splice(notificationIndex, 1);
      }
    },
    onNewEvent(): void {
      if (!this.event) return;

      const { event_type: eventType } = this.event;

      if (eventType === types.COM_DECONVE_FACEID_NOTIFICATION_CREATED) {
        const { notification_id: notificationId } = this.event;

        this.addNotification(notificationId);
      } else if (eventType === types.COM_DECONVE_FACEID_NOTIFICATION_MONITORED) {
        const { notification_id: notificationId } = this.event;

        this.removeNotification(notificationId);
      } else if (eventType === types.COM_DECONVE_FACEID_NOTIFICATION_CONFIRMED) {
        const {
          notification_id: notificationId,
          reviewer_workspace: reviewerWorkspaceId,
        } = this.event;

        const wasReviewedByAnExternalReviewer = reviewerWorkspaceId && (
          reviewerWorkspaceId !== this.myWorkspace.id);

        if (wasReviewedByAnExternalReviewer) {
          this.addNotification(notificationId);
        } else {
          this.removeNotification(notificationId);
        }
      } else if (eventType === types.COM_DECONVE_FACEID_NOTIFICATION_UNCONFIRMED) {
        const { notification_id: notificationId } = this.event;

        this.removeNotification(notificationId);
      } else if (eventType === types.COM_DECONVE_FACEID_NOTIFICATION_REVIEWED) {
        const { notification_id: notificationId } = this.event;

        this.removeNotification(notificationId);
      }
    },
    setAudio(): void {
      if (this.timer) window.clearInterval(this.timer);

      this.audio = new Audio(this.workspaceRingtone);

      // Schedule audio to play again
      this.audio.onended = () => {
        this.scheduleAudioReplay();
      };
    },
    playAudio(): void {
      if (this.audio && this.notificationIds.length > 0) {
        this.audio.play().then(() => {
          // Audio is playing
          this.isAudioSoundDisabled = false;
        }).catch((error) => {
          if (error.name === 'NotAllowedError') {
            this.isAudioSoundDisabled = true;
          } else {
            // eslint-disable-next-line no-console
            console.log(error);
          }
        });
      }
    },
    scheduleAudioReplay(): void {
      this.timer = window.setTimeout(() => {
        this.playAudio();

        window.clearTimeout(this.timer);
      }, this.intervalTimeoutMs);
    },
  },
});
