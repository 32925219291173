<template>
  <v-container
    class="d-flex flex-column justify-start"
    style="height: 100%; width: 100%"
    fluid
  >
    <v-btn
      left
      absolute
      elevation="0"
      icon
      color="neutral"
      @click="goBack"
    >
      <v-icon>{{ icons.goBack }}</v-icon>
    </v-btn>

    <v-row
      justify="center"
      class="my-0 shrink"
      :class="$vuetify.breakpoint.mobile ? 'mt-8': 'mt-0'"
    >
      <v-container
        fluid
        class="py-0"
        style="max-width: 600px"
      >
        <v-col
          id="unit-viewer-name"
        >
          <v-row
            class="align-center"
          >
            <div
              v-if="unit"
              class="subtitle-1 text-sm-h6 font-weight-bold textBlack--text"
            >
              {{ unit.name }}
            </div>
            <v-spacer />
            <v-btn
              icon
              elevation="0"
              :small="$vuetify.breakpoint.mobile"
              class="mr-1"
              @click="startTour"
            >
              <v-icon color="neutral">
                $tipsAndUpdatesIcon
              </v-icon>
            </v-btn>

            <v-menu
              offset-y
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  elevation="0"
                  v-bind="attrs"
                  :small="$vuetify.breakpoint.mobile"
                  v-on="on"
                >
                  <v-icon color="neutral">
                    {{ icons.menu }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  @click="$emit('onEditButtonClicked')"
                >
                  {{ $t('deconve.edit') }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>
          <v-row
            v-if="unit"
            class="d-flex flex-wrap align-center"
          >
            <tag
              v-for="tag in unit.tags"
              :key="tag.id"
              class="pa-1"
              :tag-id="tag.id"
            />
          </v-row>
        </v-col>
        <v-col>
          <date-time-iterator
            id="unit-viewer-date-time-iterator"
            v-model="date"
            :placeholder="$t('deconve.selectDate')"
            dialog
          />
        </v-col>
      </v-container>
    </v-row>

    <v-row
      id="unit-viewer-players"
      class="shrink black"
    />

    <v-row
      justify="center"
      class="my-0 px-2 shrink"
    >
      <v-col
        v-for="p in players"
        :key="p.id"
        cols="12"
        class="pa-1 ma-0"
        xs="12"
        sm="6"
        :md="mdColumnSize"
      >
        <player-preview
          class="pa-0 ma-0"
          :height="playerViewHeight"
          :log="p.last_log"
          :is-loading="isLoadingLogs || isLoadingPlayers"
        />
      </v-col>
    </v-row>

    <tour
      ref="tour"
      name="panel-creator-tour"
      :steps="tourSteps"
    />
    <v-spacer />
  </v-container>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { mdiChevronLeft, mdiDotsVertical } from '@mdi/js';

import DateTimeIterator from '@/components/DateTimeIterator.vue';
import Tour from '@/components/Tour.vue';
import Tag from '@/components/Tag.vue';
import PlayerPreview from './PlayerPreview.vue';

export default {
  name: 'UnitViewer',
  components: {
    DateTimeIterator,
    PlayerPreview,
    Tag,
    Tour,
  },
  props: {
    unitId: { type: String, required: true },
  },
  data() {
    return {
      unit: undefined,
      players: undefined,
      date: undefined,
      isLoadingUnit: false,
      isLoadingPlayers: false,
      isLoadingLogs: false,
      icons: {
        menu: mdiDotsVertical,
        goBack: mdiChevronLeft,
      },
    };
  },
  computed: {
    ...mapGetters({
      getUnit: 'units/getUnit',
      getUnitPlayers: 'players/getUnitPlayers',
    }),
    playerViewHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 240;
      }

      const numberOfPlayers = this.players.length;

      if (numberOfPlayers <= 2) return 480;
      if (numberOfPlayers <= 6) return 360;
      if (numberOfPlayers <= 12) return 240;
      return 200;
    },
    mdColumnSize() {
      const numberOfPlayers = this.players.length;

      if (numberOfPlayers <= 4) return 6;
      if (numberOfPlayers <= 9) return 4;
      return 3;
    },
    tourSteps() {
      const steps = [
        {
          target: '#unit-viewer-name',
          content: this.$t('deconve.onboarding.unitViewer.name'),
        },
        {
          target: '#unit-viewer-date-time-iterator',
          content: this.$t('deconve.onboarding.unitViewer.date'),
        },
        {
          target: '#unit-viewer-players',
          content: this.$t('deconve.onboarding.unitViewer.players'),
          params: {
            placement: 'top',
          },
        }];

      return steps;
    },
  },
  watch: {
    date() {
      this.onDateUpdated();
    },
  },
  created() {
    this.isLoadingUnit = true;
    this.isLoadingPlayers = true;

    this.fetchUnit(this.unitId).then((data) => {
      this.unit = data;
      this.isLoadingUnit = false;
    }).catch(() => {
      this.isLoadingUnit = false;
    });

    this.fetchCurrentPlayers(this.unitId).then((players) => {
      this.players = players;
      this.isLoadingPlayers = false;
    }).catch(() => {
      this.isLoadingPlayers = false;
    });

    this.fetchVideos();
  },
  methods: {
    ...mapActions({
      fetchUnit: 'units/fetchUnit',
      fetchCurrentPlayers: 'players/fetchCurrentPlayers',
      fetchPlayers: 'players/fetchPlayers',
      fetchVideos: 'videos/fetchVideos',
    }),
    startTour() {
      this.$refs.tour.start();
    },
    goBack() {
      this.$emit('onGoBackButtonClicked');
    },
    onDateUpdated() {
      this.isLoadingLogs = true;

      const createdAfter = this.$moment.utc(this.date).subtract(2, 'hours').toISOString();
      const createdBefore = this.date;

      const fetchOptions = {
        refDate: this.date,
        createdAfter,
        createdBefore,
        unitId: this.unitId,
      };

      this.fetchPlayers(fetchOptions).then((players) => {
        this.players = players;
        this.isLoadingLogs = false;
      }).catch(() => {
        this.isLoadingLogs = false;
      });
    },
  },
};
</script>
