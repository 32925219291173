<template>
  <v-container
    class="pa-0"
    style="overflowY: auto; height: 100%; width: 100%"
    fluid
  >
    <v-data-iterator
      class="d-flex flex-column"
      style="height: 100%"
      :items="occurrences.items"
      :loading="isLoading"
      :server-items-length="occurrences.total"
      hide-default-footer
      @update:page="onPageNumberUpdated"
    >
      <template v-slot:header>
        <div
          class="pa-3 pb-4 d-flex align-center"
          style="border-bottom: 1px solid;"
          :style="{
            borderColor: $vuetify.theme.themes.light.border,
          }"
        >
          <input-text
            v-model="search"
            max-width="440px"
            left-icon="mdi-magnify"
            :placeholder="$t('deconve.search')"
          />

          <template>
            <v-btn
              icon
              class="ml-2"
              :small="$vuetify.breakpoint.mobile"
              :disabled="isActiveDownloadButton"
              @click="getDownloadOccurrencesReport"
            >
              <v-icon v-if="!isDownloadingOccurrences">
                {{ icons.mdiDownload }}
              </v-icon>

              <v-progress-circular
                v-else
                indeterminate
                :size="20"
                :width="2.5"
                color="neutral"
              />
            </v-btn>

            <v-snackbar v-model="isToShowDownloadErrorMessage">
              <template v-slot:action="{ attrs }">
                <v-icon
                  color="error"
                  v-bind="attrs"
                  @click="isToShowDownloadErrorMessage = false"
                >
                  {{ icons.mdiClose }}
                </v-icon>
              </template>
              <span>{{ $t('deconve.error.internalError') }}</span>
            </v-snackbar>
          </template>

          <v-menu
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :color="hasFilterChanged ? 'primary' : 'neutral'"
                :class="hasFilterChanged ? 'primaryHighlight' : 'white'"
                v-bind="attrs"
                class="mx-2"
                :small="$vuetify.breakpoint.mobile"
                v-on="on"
              >
                <v-icon>
                  {{ hasFilterChanged ? 'mdi-filter':'mdi-filter-outline' }}
                </v-icon>
              </v-btn>
            </template>

            <occurrences-filter
              v-model="filterOptions"
              :has-changed="hasFilterChanged"
              @change="onFilterChanged"
              @clear="clearFilters"
            />
          </v-menu>

          <date-time-range-picker
            :start-date-time="filterOptions.createdAfter"
            :end-date-time="filterOptions.createdBefore"
            :mode="filterOptions.createdDateOption"
            @change="onCreatedAtDateChanged"
          />
        </div>
      </template>

      <template v-slot:loading>
        <span class="ml-1 pa-2">
          {{ $t('deconve.loading') }}
        </span>
      </template>

      <template v-slot:no-data>
        <span
          class="ml-1 pa-2 neutralPrimary--text
        text-subtitle-2 text-sm-subtitle-1 font-weight-bold"
        >
          {{ $t('deconve.noOccurrence') }}
        </span>
      </template>

      <template v-slot:default="props">
        <div
          style="flex: 1; overflowY: auto;"
          :style="{
            backgroundColor: $vuetify.theme.themes.light.background,
          }"
        >
          <v-progress-linear
            v-if="isLoading"
            :indeterminate="true"
          />
          <div class="d-flex flex-wrap pa-2">
            <v-col
              v-for="item in props.items"
              :key="item.id"
              class="pa-2"
              style="height: fit-content"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <occurrence-preview
                :id="item.id"
                :key="item.id"
                :data="item"
              />
            </v-col>
          </div>
        </div>
      </template>

      <template v-slot:footer="props">
        <footer-data-iterator
          :items-per-page-array="itemsPerPageArray"
          :items-per-page="itemsPerPage"
          :page="page"
          :total-data="props.pagination.itemsLength"
          @changeItensPerPage="updateItemsPerPage"
          @changePage="onPageNumberUpdated"
        />
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';

import {
  OCCURRENCE_THEFT_STATUS, OCCURRENCE_STATUS, SORT_ORDER,
} from '@/utils/faceidFilters';
import { DATE_TIME_OPTIONS, getDateAndTimeRange } from '@/utils/dateTimeRange';

import DateTimeRangePicker from '@/components/DateTimeRangePicker.vue';
import OccurrencePreview from '@/components/OccurrencePreview.vue';
import InputText from '@/components/InputText.vue';

import { mdiDownload, mdiClose } from '@mdi/js';

import FooterDataIterator from '@/components/FooterDataIterator.vue';
import OccurrencesFilter from './OccurrencesFilter.vue';

function defaultFilterOptions() {
  const createdDateOption = DATE_TIME_OPTIONS.lastTwoDays;
  const {
    startDate: createdAfter, endDate: createdBefore,
  } = getDateAndTimeRange(createdDateOption);

  return {
    createdBefore,
    createdAfter,
    createdDateOption,
    sortBy: 'created_at',
    sortOrder: SORT_ORDER.descending,
    status: OCCURRENCE_STATUS.activated,
    theftStatus: OCCURRENCE_THEFT_STATUS.all,
    noOccurrenceTags: false,
    occurrenceTags: [],
    personId: undefined,
    personName: undefined,
    unitId: undefined,
    unitName: undefined,
    videoName: undefined,
    about: undefined,
    workspaceId: undefined,
    workspaceName: undefined,
  };
}

export default {
  components: {
    OccurrencePreview,
    DateTimeRangePicker,
    OccurrencesFilter,
    FooterDataIterator,
    InputText,
  },
  data() {
    return {
      isLoading: false,
      itemsPerPageArray: [4, 8, 12],
      search: undefined,
      page: 1,
      itemsPerPage: 8,
      updateOccurrenceListTimer: '',
      filterOptions: defaultFilterOptions(),
      isToShowDownloadErrorMessage: false,
      icons: {
        mdiDownload,
        mdiClose,
      },
    };
  },
  computed: {
    ...mapGetters({
      occurrences: 'faceid/getOccurrences',
      dataIteratorSettings: 'faceid/getOccurrenceIteratorSettings',
      isDownloadingOccurrences: 'faceid/isDownloadingOccurrences',
      occurrencesDownloadError: 'faceid/occurrencesDownloadError',
    }),
    isActiveDownloadButton() {
      return this.occurrences.items.length === 0 || this.isDownloadingOccurrences;
    },
    getOccurrencesDownloadError() {
      return this.occurrencesDownloadError;
    },
    hasFilterChanged() {
      return Boolean(
        this.filterOptions.sortBy !== 'created_at'
        || this.filterOptions.sortOrder !== 'descending'
        || this.filterOptions.status !== OCCURRENCE_STATUS.activated
        || this.filterOptions.theftStatus !== OCCURRENCE_THEFT_STATUS.all
        || this.filterOptions.createdDateOption !== DATE_TIME_OPTIONS.lastTwoDays
        || this.filterOptions.noOccurrenceTags
        || this.filterOptions.occurrenceTags.length > 0
        || this.filterOptions.unitId
        || this.filterOptions.unitName
        || this.filterOptions.personId
        || this.filterOptions.workspaceId
        || this.filterOptions.workspaceName,
      );
    },
  },
  watch: {
    search() {
      this.onFilterChanged();
    },
    getOccurrencesDownloadError(newValue) {
      if (newValue) {
        this.isToShowDownloadErrorMessage = true;
      }
    },
  },
  created() {
    this.$moment.locale(this.$i18n.locale);

    if (this.$route.params.personId) {
      const {
        personId, personName, unitName,
        videoName,
        about, theftStatus, status,
      } = this.$route.params;

      this.filterOptions.personId = personId;
      this.filterOptions.personName = personName;
      this.filterOptions.unitName = unitName;
      this.filterOptions.videoName = videoName;
      this.filterOptions.about = about;
      this.filterOptions.theftStatus = theftStatus;
      this.filterOptions.status = status;
    } else {
      this.getDataIteratorInfo();
    }

    this.onFilterChanged();
    this.setTimerToUpdateOccurrenceList();
  },
  beforeDestroy() {
    clearInterval(this.updateOccurrenceListTimer);
  },
  methods: {
    ...mapActions({
      fetchOccurrences: 'faceid/fetchOccurrences',
      saveOccurrenceIteratorSettings: 'faceid/setOccurrenceIteratorSettings',
      downloadOccurrencesReport: 'faceid/downloadOccurrencesReport',
    }),
    getDownloadOccurrencesReport() {
      this.downloadOccurrencesReport();
    },
    clearFilters() {
      this.filterOptions = defaultFilterOptions();
      this.page = 1;
      this.onFilterChanged();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.page = 1;
      this.onFilterChanged();
    },
    getDataIteratorInfo() {
      if (this.dataIteratorSettings) {
        const {
          page,
          search,
          sortOrder,
          itemsPerPage,
          sortBy,
          theftStatus,
          createdDateOption,
          createdAfter,
          createdBefore,
          inTrash,
          noOccurrenceTags,
          occurrenceTags,
          personId,
          personName,
          unitId,
          unitName,
          videoName,
          about,
          workspaceId,
          workspaceName,
        } = this.dataIteratorSettings;

        let status;

        // Data-iterator updates the page value if it is greater than the number of pages availables
        if (page) {
          switch (inTrash) {
            case undefined:
              status = OCCURRENCE_STATUS.all;
              break;
            case true:
              status = OCCURRENCE_STATUS.inTrash;
              break;
            case false:
              status = OCCURRENCE_STATUS.activated;
              break;
            default:
              break;
          }

          this.page = page;
          this.itemsPerPage = itemsPerPage;
          this.search = search;

          this.filterOptions = {
            sortBy,
            sortOrder,
            status,
            theftStatus: theftStatus || OCCURRENCE_THEFT_STATUS.all,
            createdDateOption,
            createdAfter,
            createdBefore,
            noOccurrenceTags,
            occurrenceTags,
            personId,
            personName,
            unitId,
            unitName,
            videoName,
            about,
            workspaceId,
            workspaceName,
          };
        }
      }
    },
    saveIteratorSettings() {
      const {
        sortBy,
        sortOrder,
        status,
        theftStatus,
        createdDateOption,
        createdAfter,
        createdBefore,
        noOccurrenceTags,
        occurrenceTags,
        personId,
        personName,
        unitId,
        unitName,
        videoName,
        about,
        workspaceId,
        workspaceName,
      } = this.filterOptions;

      let inTrash;

      switch (status) {
        case OCCURRENCE_STATUS.inTrash:
          inTrash = true;
          break;
        case OCCURRENCE_STATUS.activated:
          inTrash = false;
          break;
        default:
          inTrash = undefined;
          break;
      }

      const dataIterator = {
        createdAfter,
        createdBefore,
        createdDateOption,
        theftStatus: theftStatus === OCCURRENCE_THEFT_STATUS.all ? undefined : theftStatus,
        inTrash,
        page: this.page,
        search: this.search,
        itemsPerPage: this.itemsPerPage,
        sortOrder,
        sortBy,
        noOccurrenceTags,
        occurrenceTags,
        personId,
        personName,
        unitId,
        unitName,
        videoName,
        about,
        workspaceId,
        workspaceName,
        locale: this.$i18n.locale,
      };

      this.saveOccurrenceIteratorSettings(dataIterator);
    },
    handleFetchOccurrences() {
      this.isLoading = true;

      this.fetchOccurrences().then(() => {
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    handleUpdateOccurrences() {
      const { startDate, endDate } = getDateAndTimeRange(this.filterOptions.createdDateOption);

      if (startDate && endDate) {
        this.filterOptions.createdBefore = endDate;
        this.filterOptions.createdAfter = startDate;

        this.saveIteratorSettings();
      }

      this.handleFetchOccurrences();
    },
    onFilterChanged() {
      this.saveIteratorSettings();
      this.handleFetchOccurrences();
    },
    onPageNumberUpdated(page) {
      this.page = page;
      this.onFilterChanged();
    },
    onCreatedAtDateChanged({ startDate, endDate, mode }) {
      this.filterOptions.createdBefore = endDate;
      this.filterOptions.createdAfter = startDate;
      this.filterOptions.createdDateOption = mode;

      this.onFilterChanged();
    },
    setTimerToUpdateOccurrenceList() {
      // TODO(spidteam): using the chat service (socket.io), we can receive events at "real time".
      // Until we have a full validation of the socket service/integration, we'll let this part to
      // force a full update in the occurrence list.
      const fiveMinutes = 5 * 60 * 1000;

      this.updateOccurrenceListTimer = setInterval(this.handleUpdateOccurrences, fiveMinutes);
    },
  },
};
</script>
