<template>
  <v-container
    class="pa-0"
    style="overflowY: auto; height: 100%; width: 100%"
    fluid
  >
    <v-card>
      <v-list>
        <v-list-item class="py-1">
          <v-select
            v-model="value.sortBy"
            hide-details
            :items="sortKeys"
            :label="$t('deconve.sortBy')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item class="py-1">
          <v-select
            v-model="value.sortOrder"
            hide-details
            :items="orderKeys"
            :label="$t('deconve.sortOrder')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item class="py-1">
          <v-select
            v-model="value.status"
            hide-details
            :items="showModeKeys"
            :label="$t('deconve.showNotifications')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item class="py-1">
          <v-select
            v-model="value.theftStatus"
            hide-details
            :items="theftStatusKeys"
            :label="$t('deconve.occurrenceStatus')"
            @change="onFilterChanged"
          />
        </v-list-item>

        <v-list-item
          v-if="isOnDeconveHubWorkspace"
          class="py-1"
        >
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.workspace') }}
              </span>
            </v-row>
            <v-row
              v-if="!value.workspaceId"
              class="py-2"
            >
              <v-btn
                outlined
                color="neutral"
                :fab="$vuetify.breakpoint.mobile"
                :x-small="$vuetify.breakpoint.mobile"
                @click="openWorkspaceDialog"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  {{ icons.addWorkspace }}
                </v-icon>

                <div v-if="!$vuetify.breakpoint.mobile">
                  {{ $t('deconve.workspace') }}
                </div>
              </v-btn>
            </v-row>

            <v-row
              v-else
              class="py-1 align-center"
            >
              <v-chip
                label
                outlined
                class="pa-1"
              >
                {{ value.workspaceName }}
              </v-chip>
              <v-btn
                outlined
                fab
                x-small
                color="neutral"
                class="ml-1"
                @click="openWorkspaceDialog"
              >
                <v-icon>
                  {{ icons.addWorkspace }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item>

        <v-list-item class="py-1">
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.unit') }}
              </span>
            </v-row>
            <v-row
              v-if="value.unitName === undefined"
              class="align-center"
            >
              <v-btn
                outlined
                color="neutral"
                :fab="$vuetify.breakpoint.mobile"
                :x-small="$vuetify.breakpoint.mobile"
                @click="openUnitModal"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  {{ icons.addUnit }}
                </v-icon>

                <div v-if="!$vuetify.breakpoint.mobile">
                  {{ $t('deconve.unit') }}
                </div>
              </v-btn>
            </v-row>

            <v-row
              v-else
              class="py-1 align-center"
            >
              <v-chip
                label
                outlined
                class="pa-1"
              >
                {{ value.unitName }}
              </v-chip>
              <v-btn
                outlined
                fab
                x-small
                color="neutral"
                class="ml-1"
                @click="openUnitModal"
              >
                <v-icon>
                  {{ icons.addUnit }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item>

        <v-list-item class="py-1">
          <v-col>
            <v-row>
              <span>
                {{ $t('deconve.occurrences') }}
              </span>
            </v-row>

            <v-row
              v-if="value.occurrenceTags.length === 0"
              class="py-1 align-center"
            >
              <v-btn
                outlined
                color="neutral"
                :fab="$vuetify.breakpoint.mobile"
                :x-small="$vuetify.breakpoint.mobile"
                @click="openNotificationTagModal"
              >
                <v-icon :left="!$vuetify.breakpoint.mobile">
                  {{ icons.addTag }}
                </v-icon>

                <div v-if="!$vuetify.breakpoint.mobile">
                  {{ $t('deconve.tags') }}
                </div>
              </v-btn>
              <v-switch
                v-model="value.noOccurrenceTags"
                inset
                hide-details
                class="ma-0 ml-4"
                :label="$t('deconve.noTags')"
                @change="onFilterChanged"
              />
            </v-row>

            <v-row
              v-else
              class="py-1 align-center"
            >
              <tag
                v-for="tagId in value.occurrenceTags"
                :key="tagId"
                class="pa-1"
                :tag-id="tagId"
              />
              <v-btn
                outlined
                fab
                x-small
                color="neutral"
                class="ml-1"
                @click="openNotificationTagModal"
              >
                <v-icon>
                  {{ icons.addTag }}
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-list-item>
      </v-list>
      <v-card-actions class="justify-end">
        <v-btn
          v-if="hasChanged"
          color="primary"
          :small="$vuetify.breakpoint.mobile"
          @click="clearFilters"
        >
          {{ $t('deconve.clearFilters') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <tags-manager-dialog ref="tagDialog" />
    <unit-selection-dialog ref="unitDialog" />
    <workspaces-dialog ref="workspaceDialog" />
  </v-container>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mdiTagPlusOutline, mdiStorePlusOutline, mdiBriefcaseOutline } from '@mdi/js';
import TagsManagerDialog from '@/components/TagsManagerDialog.vue';
import UnitSelectionDialog from '@/components/UnitSelectionDialog.vue';
import Tag from '@/components/Tag.vue';
import WorkspacesDialog from '@/components/WorkspacesDialog.vue';
import {
  OCCURRENCE_THEFT_STATUS, OCCURRENCE_STATUS, SORT_ORDER,
} from '@/utils/faceidFilters';
import SCOPES from '@/utils/scopes';

export default {
  name: 'OccurrencesFilter',
  components: {
    TagsManagerDialog,
    UnitSelectionDialog,
    Tag,
    WorkspacesDialog,
  },
  props: {
    value: { type: Object, required: true },
    hasChanged: { type: Boolean, default: false },
  },
  data() {
    return {
      sortKeys: [
        { text: this.$t('deconve.date'), value: 'created_at' },
        { text: this.$t('deconve.occurrenceValue'), value: 'value' },
      ],
      theftStatusKeys: [
        {
          text: this.$t('deconve.allFemale'),
          value: OCCURRENCE_THEFT_STATUS.all,
        },
        {
          text: this.$t('deconve.notFilled'),
          value: OCCURRENCE_THEFT_STATUS.notFilled,
        },
        {
          text: this.$t('deconve.withoutAlteration'),
          value: OCCURRENCE_THEFT_STATUS.withoutAlteration,
        },
        {
          text: this.$t('deconve.stole'),
          value: OCCURRENCE_THEFT_STATUS.stole,
        },
        {
          text: this.$t('deconve.triedToSteal'),
          value: OCCURRENCE_THEFT_STATUS.triedToSteal,
        },
      ],
      orderKeys: [
        { text: this.$t('deconve.ascendingOrder'), value: SORT_ORDER.ascending },
        { text: this.$t('deconve.descendingOrder'), value: SORT_ORDER.descending },
      ],
      showModeKeys: [
        { text: this.$t('deconve.allFemale'), value: OCCURRENCE_STATUS.all },
        { text: this.$t('deconve.actives'), value: OCCURRENCE_STATUS.activated },
        { text: this.$t('deconve.inTrash'), value: OCCURRENCE_STATUS.inTrash },
      ],
      icons: {
        addTag: mdiTagPlusOutline,
        addUnit: mdiStorePlusOutline,
        addWorkspace: mdiBriefcaseOutline,

      },
    };
  },
  computed: {
    isOnDeconveHubWorkspace() {
      return this.$can('use', 'com.deconve.hub');
    },
  },
  methods: {
    clearFilters() {
      this.$emit('clear');
    },
    openNotificationTagModal() {
      // We are updating the code style to use only the list of tag ids. After update the tag
      // dialog, we will remove this part
      const currentTags = this.value.occurrenceTags.map((id) => ({ id }));

      this.$refs.tagDialog.open(currentTags).then((tags) => {
        if (tags) {
          this.value.occurrenceTags = tags.map(({ id }) => id);
          this.onFilterChanged();
        }
      });
    },
    openUnitModal() {
      this.$refs.unitDialog.open(this.value.unitId).then((selectedUnit) => {
        this.value.unitId = selectedUnit?.id;
        this.value.unitName = selectedUnit?.name;
        this.onFilterChanged();
      }).catch(() => {
        // User canceled the dialog
      });
    },
    onFilterChanged() {
      this.$emit('change');
    },
    deleteFilterByPerson() {
      this.value.personId = undefined;
      this.value.personName = undefined;
      this.onFilterChanged();
    },
    openWorkspaceDialog() {
      const options = {
        workspaceId: this.value.workspaceId,
        scopes: [SCOPES.READ_COM_DECONVE_FACEID_OCCURRENCE],
      };

      this.$refs.workspaceDialog.open(options).then((workspace) => {
        this.value.workspaceId = workspace?.id;
        this.value.workspaceName = workspace?.name;

        this.onFilterChanged();
      }).catch(() => {
        // User canceled the dialog
      });
    },
  },
};
</script>
