<template>
  <v-card
    class="d-flex flex-column fill-height"
    :height="`${height}px`"
    :loading="isLoading"
    outlined
    flat
    min-height="200px"
    width="100%"
  >
    <v-row
      no-gutters
      class="transparentBackground shrink"
      justify="center"
      align="center"
    >
      <v-img
        v-if="videoFrame"
        class="transparentBackground"
        :height="imageHeight"
        contain
        :src="videoFrame.original_url"
      />
      <div
        v-else
        class="d-flex flex-column align-center justify-center"
        :style="{height: imageHeight}"
      >
        <v-icon
          size="120px"
          color="neutral"
        >
          {{ icons.imageUnavailable }}
        </v-icon>
        <div class="text-body-5 neutral--text">
          {{ $t('deconve.imageUnavailable') }}
        </div>
      </div>
    </v-row>
    <v-row
      class="fill-height d-flex flex-column mt-0"
      no-gutters
      :class="alertColor"
    >
      <v-col>
        <v-row
          class="mt-1 ml-2"
          align="center"
          no-gutters
        >
          <v-icon
            left
            :small="$vuetify.breakpoint.mobile"
          >
            {{ icons.video }}
          </v-icon>
          <span
            v-if="video"
            class="caption text-sm-body-2"
          >
            {{ video.name }}
          </span>
        </v-row>
        <v-row
          class="mt-1 ml-2"
          align="center"
          no-gutters
        >
          <v-icon
            left
            :small="$vuetify.breakpoint.mobile"
          >
            {{ icons.calendar }}
          </v-icon>
          <span
            class="caption text-sm-body-2 mr-5"
          >
            {{ logDateTime }}
          </span>
          <v-icon
            v-if="alertMessage"
            left
            :small="$vuetify.breakpoint.mobile"
          >
            {{ icons.alert }}
          </v-icon>
          <span
            class="caption text-sm-body-2"
          >
            {{ alertMessage }}
          </span>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { mapGetters } from 'vuex';
import {
  mdiAlert, mdiImageBroken, mdiPlayBox, mdiUpdate,
} from '@mdi/js';

import { PLAYER_STATUS } from '@/store/players';

export default {
  name: 'PlayerPreview',
  props: {
    height: { type: Number, default: 300 },
    log: { type: Object, default: null },
    isLoading: { type: Boolean, required: true },
  },
  data() {
    return {
      icons: {
        alert: mdiAlert,
        calendar: mdiUpdate,
        imageUnavailable: mdiImageBroken,
        video: mdiPlayBox,
      },
    };
  },
  computed: {
    ...mapGetters({
      getVideo: 'videos/getVideo',
      getUnit: 'units/getUnit',
    }),
    video() {
      if (this.log?.video) {
        const { id: videoId } = this.log.video;

        return this.getVideo(videoId);
      }

      return null;
    },
    imageHeight() {
      const cardActionSize = this.$vuetify.breakpoint.xsOnly ? 50 : 60;

      return `${this.height - cardActionSize}px`;
    },
    videoFrame() {
      return this.log?.video_frame || undefined;
    },
    logDateTime() {
      if (this.log?.created_at) {
        return this.$moment(this.log?.created_at).format('DD/MM/YYYY HH:mm:ss');
      }

      return '';
    },
    alertColor() {
      if (this.log?.status) {
        const { id: logId } = this.log.status;

        switch (logId) {
          case PLAYER_STATUS.offline:
            return 'error';
          case PLAYER_STATUS.stopped:
            return 'error';
          case PLAYER_STATUS.running:
            return 'success';
          default:
            break;
        }
      }

      return 'neutral';
    },
    alertMessage() {
      if (this.log?.status) {
        const { id: logId } = this.log.status;

        switch (logId) {
          case PLAYER_STATUS.offline:
            return this.$t('deconve.playerOffline');
          case PLAYER_STATUS.stopped:
            return this.$t('deconve.playerStopped');
          default:
            break;
        }
      }

      return '';
    },
  },
};
</script>
