// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';
import { NOTIFICATION_REVIEW_STATUS } from '@/utils/faceidFilters';
import {
  Notification,
  NotificationFilterSettings,
  NotificationPreviewPage,
  NotificationState,
  types,
} from './types';
import { RootState } from '../../types';

import { isCachedDataValid, prepareNotificationToBeCached } from './utils';
import { getDemoNotifications, getDemoNotification } from './demo';

interface FilterNotificationsRequestParams {
  limit: number;
  skip: number;
  search?: string;
  sort_by: string;
  sort_order: string;
  person_id?: string;
  theft_status?: string;
  in_trash?: boolean;
  created_after?: string;
  created_before?: string;
  unit_id?: string;
  review_status?: string;
}

interface FilterUnitNotificationOptions {
  page: number;
  itemsPerPage: number;
  search: string;
  createdAfter: string;
  createdBefore: string;
  unitId: string;
}

interface FilterProfileNotificationsRequestParams {
  limit: number;
  skip: number;
  search: string;
  created_after: string | undefined;
  created_before: string | undefined;
  review_status: string | undefined;
  person_id?: string;
  super_profile_id?: string;
}

interface FilterProfileNotificationsOptions {
  page: number;
  itemsPerPage: number;
  search: string;
  createdAfter: string | undefined;
  createdBefore: string | undefined;
  reviewStatus: string;
  personId?: string;
  superProfileId?: string;
}

function parseTagsToUrlPattern(prefix: string, noTags: boolean, tags: string[]): string {
  if (noTags) {
    return prefix;
  }

  return tags.map((tag) => `${prefix}=${tag}`).join('&');
}

export const actions: ActionTree<NotificationState, RootState> = {
  fetchNotifications(
    { commit, state, rootGetters },
  ): Promise<NotificationPreviewPage | undefined> {
    return new Promise((resolve, reject) => {
      if (!state.notificationIteratorSettings) {
        resolve(undefined);
        return;
      }

      const {
        page,
        itemsPerPage,
        sortBy,
        sortOrder,
        search,
        reviewStatus,
        inTrash,
        notificationTags,
        noNotificationTags,
        peopleTags,
        noPeopleTags,
        personId,
        createdAfter,
        createdBefore,
        unitId,
      } = state.notificationIteratorSettings as NotificationFilterSettings;

      const skip = itemsPerPage * (page - 1);

      let url = '/faceid/notifications/';

      let urlParams = parseTagsToUrlPattern('tag_ids', noNotificationTags, notificationTags);
      const peopleTagsParams = parseTagsToUrlPattern('people_tag_ids', noPeopleTags, peopleTags);

      if (urlParams.length > 0 && peopleTagsParams.length > 0) {
        urlParams += '&';
      }

      urlParams += peopleTagsParams;

      const params: FilterNotificationsRequestParams = {
        skip,
        limit: itemsPerPage,
        search,
        // eslint-disable-next-line @typescript-eslint/camelcase
        person_id: personId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        review_status: reviewStatus,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: sortBy,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_order: sortOrder,
        // eslint-disable-next-line @typescript-eslint/camelcase
        in_trash: inTrash,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_after: createdAfter,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_before: createdBefore,
      };

      if (urlParams.length > 0) {
        url += `?${urlParams}`;
      }

      if (unitId) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        params.unit_id = unitId;
      }

      const requestOptions: AxiosRequestConfig = {
        url,
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
        params,
      };

      if (rootGetters.isDemoMode) {
        getDemoNotifications().then((data) => {
          commit(types.GET_FACEID_NOTIFICATIONS_SUCCESS, data);
          resolve(data);
        });
      } else {
        axios(requestOptions).then((response) => {
          const { data } = response;

          commit(types.GET_FACEID_NOTIFICATIONS_SUCCESS, data);
          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  },
  fetchProfileNotifications(
    { rootGetters }, options: FilterProfileNotificationsOptions,
  ): Promise<NotificationPreviewPage | undefined> {
    return new Promise((resolve, reject) => {
      const {
        page,
        itemsPerPage,
        search,
        createdAfter,
        createdBefore,
        reviewStatus,
      } = options;

      const skip = itemsPerPage * (page - 1);
      const url = '/faceid/notifications/';

      let params: FilterProfileNotificationsRequestParams = {
        skip,
        limit: itemsPerPage,
        search,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_after: createdAfter,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_before: createdBefore,
        // eslint-disable-next-line @typescript-eslint/camelcase
        review_status: reviewStatus === NOTIFICATION_REVIEW_STATUS.all ? undefined : reviewStatus,

      };

      if (options?.superProfileId) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        params = { ...params, super_profile_id: options.superProfileId };
      } else {
        // eslint-disable-next-line @typescript-eslint/camelcase
        params = { ...params, person_id: options.personId };
      }

      const requestOptions: AxiosRequestConfig = {
        url,
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
        params,
      };

      if (rootGetters.isDemoMode) {
        getDemoNotifications().then((data) => {
          resolve(data);
        });
      } else {
        axios(requestOptions).then((response) => {
          const { data } = response;

          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  },
  fetchUnitNotifications(
    { rootGetters }, options: FilterUnitNotificationOptions,
  ): Promise<NotificationPreviewPage | undefined> {
    return new Promise((resolve, reject) => {
      const {
        page,
        itemsPerPage,
        search,
        createdAfter,
        createdBefore,
        unitId,
      } = options;

      const skip = itemsPerPage * (page - 1);
      const url = '/faceid/notifications/';

      const params: FilterNotificationsRequestParams = {
        skip,
        limit: itemsPerPage,
        search,
        // eslint-disable-next-line @typescript-eslint/camelcase
        review_status: 'yes',
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: 'created_at',
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_order: 'descending',
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_after: createdAfter,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_before: createdBefore,
        // eslint-disable-next-line @typescript-eslint/camelcase
        unit_id: unitId,
      };

      const requestOptions: AxiosRequestConfig = {
        url,
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
        params,
      };

      if (rootGetters.isDemoMode) {
        getDemoNotifications().then((data) => {
          resolve(data);
        });
      } else {
        axios(requestOptions).then((response) => {
          const { data } = response;

          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  },
  fetchNotificationHelper({ commit, rootGetters }, id): Promise<Notification> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/notifications/${id}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      if (rootGetters.isDemoMode) {
        getDemoNotification(id).then((notification) => {
          const output = prepareNotificationToBeCached(notification);

          commit(types.GET_FACEID_NOTIFICATION_SUCCESS, output);
          resolve(output);
        });
      } else {
        axios(requestOptions).then((response) => {
          let { data: notification } = response as { data: Notification };

          notification = prepareNotificationToBeCached(notification);
          commit(types.GET_FACEID_NOTIFICATION_SUCCESS, notification);
          resolve(notification);
        }).catch((error) => reject(error));
      }
    });
  },
  fetchNotification({ dispatch, getters }, id): Promise<Notification> {
    return new Promise((resolve) => {
      const cachedNotification = getters.getNotification(id);

      if (isCachedDataValid(cachedNotification)) {
        resolve(cachedNotification);
      } else {
        resolve(dispatch('fetchNotificationHelper', id));
      }
    });
  },
  deleteNotification({ commit, rootGetters }, id): Promise<void> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'delete',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/notifications/${id}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions)
        .then(() => {
          commit(types.DELETE_FACEID_NOTIFICATION_SUCCESS, id);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  editNotification({ commit, rootGetters }, { id, data }): Promise<Notification> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        data,
        method: 'put',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/notifications/${id}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions)
        .then((response) => {
          let { data: notification } = response;

          notification = prepareNotificationToBeCached(notification);

          commit(types.PUT_FACEID_NOTIFICATION_SUCCESS, notification);
          resolve(notification);
        })
        .catch((error) => reject(error));
    });
  },
  monitorNotification({ rootGetters }, id): Promise<void> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'post',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/notifications/${id}/actions/monitoring/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions)
        .then(() => {
          resolve();
        })
        .catch((error) => reject(error));
    });
  },
  setNotificationIteratorSettings({ commit }, data) {
    commit('setNotificationIteratorSettings', data);
  },
  onSocketEventNotificationCreated({ dispatch }) {
    dispatch('fetchNotifications');
  },
  onSocketEventNotificationDeleted({ commit, dispatch }, notificationId: string) {
    commit(types.DELETE_FACEID_NOTIFICATION_SUCCESS, notificationId);
    dispatch('fetchNotifications');
  },
  onSocketEventNotificationReviewed({ dispatch }, notificationId: string) {
    dispatch('fetchNotificationHelper', notificationId);
  },
  onSocketEventNotificationMonitored({ dispatch }, notificationId: string) {
    dispatch('fetchNotificationHelper', notificationId);
  },
  onSocketEventNotificationUpdated({ dispatch }, notificationId: string) {
    dispatch('fetchNotificationHelper', notificationId);
  },
  onSocketEventNotificationRestored({ dispatch }, notificationId: string) {
    dispatch('fetchNotifications');
    dispatch('fetchNotificationHelper', notificationId);
  },
  onSocketEventNotificationTrashed({ dispatch }, notificationId: string) {
    dispatch('fetchNotifications');
    dispatch('fetchNotificationHelper', notificationId);
  },
};

export default actions;
