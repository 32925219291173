<template>
  <v-container
    class="d-flex flex-column px-6 pt-6 pb-0"
    style="overflowY: auto; height: 100%; width: 100%"
    fluid
  >
    <v-btn
      left
      absolute
      elevation="0"
      icon
      color="neutral"
      style="top: 10px; left: 10px;"
      @click="goBack"
    >
      <v-icon>
        {{ icons.chevronLeft }}
      </v-icon>
    </v-btn>
    <v-col
      class="mb-9"
      style="flex: 0"
    >
      <v-row
        class="mt-4 text-h5 font-weight-bold textBlack--text"
      >
        {{ $t('deconve.newUser') }}
      </v-row>
    </v-col>

    <div>
      <v-stepper
        v-model="currentStep"
        class="stepper-flat mb-4"
      >
        <v-stepper-header>
          <v-stepper-step
            :complete="currentStep > 1"
            :rules="[() => isEmailStepValid]"
            :class="!isEmailStepValid ? 'stepper-text-shadow' : ''"
            step="1"
          >
            {{ this.$t('deconve.email') }}
            <small
              v-if="!isEmailStepValid && !isEmailValid"
              class="mt-1"
            >{{ this.$t('deconve.userEmailNotFilled') }}</small>
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="currentStep > 2"
            :rules="[() => isProfileStepValid]"
            :class="!isProfileStepValid ? 'stepper-text-shadow' : ''"
            step="2"
          >
            {{ this.$t('deconve.person.profile') }}
            <small
              v-if="!isProfileStepValid && !isProfileValid"
              class="mt-1"
            >{{ this.$t('deconve.userProfileNotSelected') }}</small>
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="currentStep > 3"
            step="3"
          >
            {{ this.$t('deconve.userNotifications') }}
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="currentStep > 4"
            step="4"
          >
            {{ this.$t('deconve.userRestriction') }}
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="5">
            {{ this.$t('deconve.done') }}
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </div>

    <v-divider />

    <!-- Content -->
    <v-col
      v-if="currentStep === 1"
      class="mt-4 mb-1"
      style="flex: 0"
    >
      <v-row
        class="mt-0 text-h6 font-weight-medium textBlack--text"
      >
        {{ this.$t('deconve.userEmail') }}
      </v-row>
    </v-col>
    <v-col
      v-if="currentStep === 1"
      class="pl-0"
    >
      <div class="d-flex mt-8 justify-space-between align-center">
        <span class="font-weight-medium caption text-sm-body-2 mb-1">
          {{ $t('deconve.email') }}
        </span>
      </div>
      <input-text
        v-model="email"
        name="email"
        data-cy="invite-input-email"
        :is-error="!isStepValid && !isEmailValid ? true : false"
        tooltip-color="red"
        :show-tooltip="!isStepValid && !isEmailValid ? true : false"
        :tooltip-message="!isStepValid && !isEmailValid ?
          this.$t('deconve.userEmailRequired') : ''"
        no-spaces
        max-width="400px"
      />

      <v-divider class="mt-7 mb-4" />

      <div class="d-flex justify-end">
        <v-btn
          @click="goBack"
        >
          {{ $t('deconve.goBack') }}
        </v-btn>
        <v-btn
          class="ml-3"
          color="primary"
          @click="nextStep"
        >
          {{ $t('deconve.next') }}
        </v-btn>
      </div>
    </v-col>

    <v-col
      v-if="currentStep === 2"
      class="mt-4 mb-1"
      style="flex: 0"
    >
      <v-row
        class="mt-0 text-h6 font-weight-medium textBlack--text"
      >
        {{ this.$t('deconve.userProfile') }}
      </v-row>
    </v-col>
    <v-col
      v-if="currentStep === 2"
      class="pl-0"
    >
      <div class="d-flex mt-6 pt-3 justify-space-between align-center">
        <span class="font-weight-medium caption text-sm-body-2 mb-1">
          {{ $t('deconve.userSelectEmailAndProfile') }}
        </span>
      </div>
      <v-row class="mb-4">
        <v-col
          v-for="card in profileCards"
          :key="card.id"
          cols="12"
          sm="6"
          md="3"
        >
          <v-card
            :outlined="selectedProfileId === card.id ? false : true"
            :elevation="selectedProfileId === card.id ? 2 : 0"
            :style="selectedProfileId === card.id ? 'border: 1px solid black;' : ''"
            @click="selectProfile(card.id)"
          >
            <v-card-title class="pb-2">
              <v-icon>
                {{ icons[card.type] }}
              </v-icon>
            </v-card-title>
            <v-card-text class="pb-1 font-weight-medium text-sm-body-2 textBlack--text">
              {{ card.title }}
            </v-card-text>
            <v-card-text class="pt-0">
              {{ card.text }}
            </v-card-text>
            <v-card-text
              v-if="card.details"
              class="pt-0 pb-0"
            >
              <v-list dense>
                <v-list-item
                  v-for="(detail, index) in card.details"
                  :key="index"
                  class="pl-0"
                  style="min-height: 30px"
                >
                  <v-list-item-title>{{ detail }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-divider class="mb-4" />

      <div class="d-flex justify-end">
        <v-btn
          @click="prevStep"
        >
          {{ $t('deconve.goBack') }}
        </v-btn>
        <v-btn
          class="ml-3"
          color="primary"
          @click="nextStep"
        >
          {{ $t('deconve.next') }}
        </v-btn>
      </div>
    </v-col>

    <v-col
      v-if="currentStep === 3"
      class="mt-4 mb-1"
      style="flex: 0"
    >
      <v-row
        class="mt-0 text-h6 font-weight-medium textBlack--text"
      >
        {{ this.$t('deconve.userMobileNotifications') }}
      </v-row>
    </v-col>
    <v-col
      v-if="currentStep === 3"
      class="pl-0"
    >
      <div class="d-flex mt-8 justify-space-between align-center">
        <span class="font-weight-medium caption text-sm-body-2 mb-1">
          {{ $t('deconve.enableMobileNotifications') }}
        </span>
      </div>
      <v-switch
        v-model="enableMobileNotifications"
        data-cy="enable-mobile-notifications"
        inset
        :label="enableMobileNotifications? $t('deconve.yes') : $t('deconve.no')"
      />

      <div
        v-if="enableMobileNotifications"
        class="d-flex mt-6 pt-2 justify-space-between align-center"
      >
        <span class="font-weight-medium caption text-sm-body-2 mb-1">
          {{ $t('deconve.typesOfMobileNotifications') }}
        </span>
      </div>
      <div
        v-if="enableMobileNotifications"
        class="d-flex align-center"
      >
        <v-switch
          v-if="enableMobileNotifications"
          v-model="enableReceiveConfirmedNotifications"
          class="caption text-sm-body-2"
          data-cy="enable-mobile-notification-confirmed"
          inset
          :label="$t('deconve.mobileNotificationConfirmed')"
        />
      </div>
      <div
        v-if="enableMobileNotifications && isOnDeconveHubWorkspace"
        class="d-flex align-center"
      >
        <v-switch
          v-if="enableMobileNotifications"
          v-model="enableReceiveCreatedNotifications"
          data-cy="enable-mobile-notification-created"
          inset
          :label="$t('deconve.mobileNotificationCreated')"
        />
      </div>

      <v-divider class="mt-2 mb-4" />

      <div class="d-flex justify-end">
        <v-btn
          @click="prevStep"
        >
          {{ $t('deconve.goBack') }}
        </v-btn>
        <v-btn
          class="ml-3"
          color="primary"
          @click="nextStep"
        >
          {{ $t('deconve.next') }}
        </v-btn>
      </div>
    </v-col>

    <v-col
      v-if="currentStep === 4"
      class="mt-4"
      style="flex: 0"
    >
      <v-row
        class="mt-0 text-h6 font-weight-medium textBlack--text"
      >
        {{ this.$t('deconve.userAccessRestriction') }}
      </v-row>
    </v-col>
    <v-col
      v-if="currentStep === 4"
      class="pl-0 pt-1"
    >
      <div class="d-flex mb-4 justify-space-between align-center">
        <span
          class="neutral--text
                    font-weight-medium
                    text-body-sm-1
                    text-body-2 mb-1"
        >
          {{ this.$t('deconve.userAccessRestrictionInfo') }}
        </span>
      </div>
      <v-col
        class="pa-0"
        style="flex: 0; max-width: 800px"
      >
        <v-list
          style="width: 100%"
        >
          <v-list-item
            class="rounded px-4 py-2 mt-4"
            style="width: 100%"
            :style="{
              border: '1px solid',
              borderColor: $vuetify.theme.themes.light.border,
              minHeight: '64px'
            }"
          >
            <v-list-item-title
              style="flex-wrap: wrap; whiteSpace: normal"
              class="neutralPrimary--text subtitle-1"
            >
              {{ this.$t('deconve.userAccessRestrictionQuestion') }}
            </v-list-item-title>
            <v-list-item-action>
              <v-switch
                v-model="allowAllAccess"
                data-cy="set-person-tag-required"
                :disabled="!$can('update', 'com.deconve.workspace.settings')"
                inset
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>

      <rectangle-button
        v-if="tags.length === 0 && allowAllAccess"
        class="mt-8"
        icon="mdi-tag-plus-outline"
        outlined
        color="neutral"
        data-cy="apikeymanager-add-tags"
        @clicked="openTagModal"
      >
        {{ $t('deconve.tag.addTags') }}
      </rectangle-button>

      <div class="d-flex flex-wrap mt-2">
        <tag
          v-for="(tag, index) in tags"
          :key="tag.id"
          show-delete-label
          class="pa-1"
          :tag-id="tag.id"
          @delete="deleteTag(index)"
        />
        <div
          v-if="tags.length > 0"
          class="d-flex flex-column ml-2"
          style="flex: 1"
        />
      </div>

      <v-divider class="mt-3 mb-4" />

      <div class="d-flex justify-end">
        <v-btn
          @click="prevStep"
        >
          {{ $t('deconve.goBack') }}
        </v-btn>
        <v-btn
          class="ml-3"
          color="primary"
          @click="nextStep"
        >
          {{ $t('deconve.next') }}
        </v-btn>
      </div>
    </v-col>

    <v-col
      v-if="currentStep === 5"
      class="mt-4"
      style="flex: 0"
    >
      <v-row
        class="mt-0 text-h6 font-weight-medium textBlack--text"
      >
        {{ this.$t('deconve.userOverview') }}
      </v-row>
    </v-col>
    <v-col
      v-if="currentStep === 5"
      class="pl-0 pt-1"
    >
      <div class="d-flex mb-4 justify-space-between align-center">
        <span
          class="neutral--text
                    font-weight-medium
                    text-body-sm-1
                    text-body-2 mb-1"
        >
          {{ this.$t('deconve.userOverviewInfo') }}
        </span>
      </div>
      <div class="d-flex mt-8 pt-4 justify-space-between align-center">
        <span
          class="subtitle-1 font-weight-medium textBlack--text"
        >
          {{ $t('deconve.userEmail') }}
        </span>
      </div>
      <div
        class="mb-4 subtitle-2 font-weight-regular textBlack--text"
      >
        {{ email }}
      </div>

      <div class="d-flex mt-8 justify-space-between align-center">
        <span
          class="subtitle-1 font-weight-medium textBlack--text"
        >
          {{ this.$t('deconve.userProfile') }}
        </span>
      </div>
      <div
        class="mb-4 subtitle-2 font-weight-regular textBlack--text"
      >
        {{ selectedProfileTitle }}
      </div>

      <div class="d-flex mt-8 justify-space-between align-center">
        <span
          class="subtitle-1 font-weight-medium textBlack--text"
        >
          {{ this.$t('deconve.userMobileNotifications') }}
        </span>
      </div>
      <div
        class="mb-4 subtitle-2 font-weight-regular textBlack--text"
      >
        {{ enableMobileNotifications ? this.$t('deconve.yes') : this.$t('deconve.no') }}
      </div>

      <div class="d-flex mt-8 justify-space-between align-center">
        <span
          class="subtitle-1 font-weight-medium textBlack--text"
        >
          {{ $t('deconve.userAccessRestriction') }}
        </span>
      </div>
      <div class="d-flex flex-wrap mb-4">
        <tag
          v-for="(tag) in tags"
          :key="tag.id"
          class="pa-1"
          :tag-id="tag.id"
        />
        <div
          v-if="tags.length > 0"
          class="d-flex flex-column ml-2"
          style="flex: 1"
        />
        <div
          v-else
          class="mb-4 subtitle-2 font-weight-regular textBlack--text"
        >
          {{ this.$t('deconve.no') }}
        </div>
      </div>

      <v-divider class="mt-4 mb-4" />

      <div class="d-flex justify-end">
        <v-btn @click="prevStep">
          {{ $t('deconve.goBack') }}
        </v-btn>
        <v-btn
          class="ml-3"
          color="success"
          :disabled="!isStepValid"
          :loading="isLoading"
          @click="submit"
        >
          {{ $t('deconve.submit') }}
        </v-btn>
      </div>
    </v-col>

    <scopes-manager-dialog ref="scopesDialog" />
    <tags-manager-dialog ref="tagsDialog" />
  </v-container>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions } from 'vuex';
import InputText from '@/components/InputText.vue';
import RectangleButton from '@/components/RectangleButton.vue';
import Tag from '@/components/Tag.vue';
import TagsManagerDialog from '@/components/TagsManagerDialog.vue';
import ScopesManagerDialog from '@/components/ScopesManagerDialog.vue';
import {
  mdiSecurity, mdiFaceAgent, mdiTools, mdiFaceMan, mdiFaceRecognition, mdiStorePlus, mdiChevronLeft,
} from '@mdi/js';

import { getProfiles } from './profileDefinitions';

export default {
  name: 'InviteUsers',
  components: {
    ScopesManagerDialog,
    RectangleButton,
    InputText,
    Tag,
    TagsManagerDialog,
  },
  data() {
    return {
      email: '',
      isAdmin: false,
      allowAllAccess: false,
      currentStep: 1,
      isStepValid: true,
      isEmailStepValid: true,
      isProfileStepValid: true,
      enableMobileNotifications: false,
      enableReceiveCreatedNotifications: false,
      enableReceiveConfirmedNotifications: false,
      isLoading: false,
      selectedProfileId: '',
      tags: [],
      eventTypes: [],
      userCustomScopes: [],
      profileCards: getProfiles(),
      icons: {
        admin: mdiSecurity,
        reviewer: mdiFaceAgent,
        operator: mdiFaceRecognition,
        monitor: mdiFaceMan,
        custom: mdiTools,
        storePlus: mdiStorePlus,
        chevronLeft: mdiChevronLeft,
      },
    };
  },
  computed: {
    isOnDeconveHubWorkspace() {
      return this.$can('use', 'com.deconve.hub');
    },
    receiveCreatedNotificationsEnabled() {
      return this.enableReceiveCreatedNotifications ? 'com.deconve.faceid.notification:created' : null;
    },
    receiveConfirmedNotificationsEnabled() {
      return this.enableReceiveConfirmedNotifications ? 'com.deconve.faceid.notification:confirmed' : null;
    },
    isEmailValid() {
      return !!this.email;
    },
    isProfileValid() {
      return this.selectedProfileId?.length > 0;
    },
    userPreDefinedScopes() {
      const selectedCard = this.profileCards.find((card) => card.id === this.selectedProfileId);

      return selectedCard ? selectedCard.scopes : [];
    },
    selectedProfileTitle() {
      const selectedCard = this.profileCards.find((card) => card.id === this.selectedProfileId);

      return selectedCard ? selectedCard.title : '';
    },
  },
  watch: {
    enableReceiveCreatedNotifications() {
      this.updateEnableMobileNotifications();
    },
    enableReceiveConfirmedNotifications() {
      this.updateEnableMobileNotifications();
    },
    isEmailValid: 'validateStepForEmail',
    isProfileValid: 'validateStepForProfile',
  },
  methods: {
    ...mapActions({
      inviteUserToWorkspace: 'workspace/inviteUserToWorkspace',
    }),
    updateEnableMobileNotifications() {
      if (this.enableReceiveCreatedNotifications || this.enableReceiveConfirmedNotifications) {
        this.enableMobileNotifications = true;
      } else {
        this.enableMobileNotifications = false;
      }
    },
    updateEventTypes() {
      const newEventTypes = [
        this.receiveCreatedNotificationsEnabled,
        this.receiveConfirmedNotificationsEnabled,
      ].filter(Boolean);

      this.eventTypes = newEventTypes;
    },
    openTagModal() {
      this.$refs.tagsDialog.open(this.tags, true).then((tags) => {
        if (tags) {
          this.tags = tags.map((tag) => ({ id: tag.id }));
        }
      });
    },
    openScopesModal() {
      this.$refs.scopesDialog.open({ title: this.$t('deconve.userPermissions'), scopes: this.userCustomScopes })
        .then((scopes) => {
          if (scopes.length > 0) {
            this.userCustomScopes = scopes;
          }
        });
    },
    selectProfile(id) {
      if (id === 'custom') {
        this.openScopesModal();
      }

      this.selectedProfileId = id;
    },
    validateStepForEmail() {
      this.isEmailStepValid = this.isEmailValid;
      this.isStepValid = this.isEmailStepValid;
      return this.isEmailStepValid;
    },
    validateStepForProfile() {
      this.isProfileStepValid = this.isProfileValid;
      this.isStepValid = this.isProfileStepValid;
      return this.isProfileStepValid;
    },
    validateStep() {
      if (this.currentStep === 1) {
        return this.validateStepForEmail();
      }

      if (this.currentStep === 2) {
        return this.validateStepForProfile();
      }

      return true;
    },
    nextStep() {
      if (this.currentStep < 5) {
        if (this.validateStep()) {
          this.currentStep += 1;
        }
      }
    },
    prevStep() {
      if (this.currentStep > 1) this.currentStep -= 1;
    },
    submit() {
      const tagIds = this.tags.map(({ id }) => id);

      this.updateEventTypes();

      if (this.selectedProfileId === 'admin') {
        this.isLoading = true;
        this.inviteUserToWorkspace({
          tagIds,
          email: this.email.toLowerCase(),
          role: 'admin',
          scopes: [],
          eventTypes: this.eventTypes,
        }).then(() => {
          this.isLoading = false;
          this.$router.push({ name: 'settings-users' });
        });
      } else if (this.selectedProfileId === 'custom') {
        this.isLoading = true;
        this.inviteUserToWorkspace({
          scopes: this.userCustomScopes,
          tagIds,
          email: this.email.toLowerCase(),
          role: 'user',
          eventTypes: this.eventTypes,
        }).then(() => {
          this.isLoading = false;
          this.$router.push({ name: 'settings-users' });
        });
      } else {
        this.isLoading = true;
        this.inviteUserToWorkspace({
          scopes: this.userPreDefinedScopes,
          tagIds,
          email: this.email.toLowerCase(),
          role: 'user',
          eventTypes: this.eventTypes,
        }).then(() => {
          this.isLoading = false;
          this.$router.push({ name: 'settings-users' });
        });
      }
    },
    deleteTag(index) {
      this.tags.splice(index, 1);
    },
    goBack() {
      this.$router.push({ name: 'settings-users' });
    },
  },
};
</script>

<style>
.stepper-flat {
  box-shadow: none !important;
}

.stepper-text-shadow .v-stepper__label {
  text-shadow: none !important;
}
</style>
