<template>
  <div>
    <v-data-iterator
      class="d-flex flex-column pb-4"
      :items="notifications.items"
      :loading="isLoading"
      :server-items-length="notifications.total"
      hide-default-footer
    >
      <template v-slot:header>
        <div
          class="px-1 py-4 d-flex align-center"
        >
          <input-text
            v-model="filterOptions.search"
            max-width="440px"
            left-icon="mdi-magnify"
            :placeholder="$t('deconve.search')"
          />

          <v-menu
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :color="hasFilterChanged ? 'primary' : 'neutral'"
                :class="hasFilterChanged ? 'primaryHighlight' : 'white'"
                v-bind="attrs"
                class="mx-2"
                :small="$vuetify.breakpoint.mobile"
                v-on="on"
              >
                <v-icon>
                  {{ hasFilterChanged ? 'mdi-filter':'mdi-filter-outline' }}
                </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item class="py-1">
                  <v-select
                    v-model="filterOptions.reviewStatus"
                    hide-details
                    :items="reviewStatusKeys"
                    :label="$t('deconve.revision')"
                    @change="onFilterChanged"
                  />
                </v-list-item>
              </v-list>

              <v-card-actions class="justify-end">
                <v-btn
                  color="primary"
                  :disabled="!hasFilterChanged"
                  :small="$vuetify.breakpoint.mobile"
                  @click="clearFilters"
                >
                  {{ $t('deconve.clearFilters') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <date-time-range-picker
            :start-date-time="filterOptions.createdAfter"
            :end-date-time="filterOptions.createdBefore"
            :mode="filterOptions.createdDateOption"
            :any-period="true"
            @change="onCreatedAtDateChanged"
          />
        </div>
      </template>

      <template v-slot:loading>
        <span class="ml-1 pa-2">
          {{ $t('deconve.loading') }}
        </span>
      </template>

      <template v-slot:default="props">
        <div>
          <v-progress-linear
            v-if="isLoading"
            :indeterminate="true"
          />

          <div class="d-flex flex-wrap">
            <v-col
              v-for="item in props.items"
              :key="item.id"
              class="pa-1"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <notification-image-preview
                :id="item.id"
                :key="item.id"
                :is-selected="currentNotificationId === item.id"
                :data="item"
              />
            </v-col>
          </div>

          <v-row
            justify="center"
            class="mt-2"
          >
            <v-btn
              v-if="hasMoreNotifications"
              color="neutral"
              text
              plain
              elevation="0"
              @click="loadNotifications"
            >
              {{ $t('deconve.loadMore') }}
            </v-btn>
          </v-row>
        </div>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { NOTIFICATION_REVIEW_STATUS, defaultRelatedNotificationsFilterOptions } from '@/utils/faceidFilters';
import NotificationImagePreview from '@/components/NotificationImagePreview.vue';
import InputText from '@/components/InputText.vue';
import DateTimeRangePicker from '@/components/DateTimeRangePicker.vue';

export default {
  name: 'Notifications',
  components: {
    NotificationImagePreview,
    InputText,
    DateTimeRangePicker,
  },
  props: {
    personId: { type: String, default: undefined },
    superProfileId: { type: String, default: undefined },
  },
  data() {
    return {
      filterOptions: defaultRelatedNotificationsFilterOptions(),
      page: 1,
      itemsPerPage: 8,
      isLoading: false,
      notifications: [],
      reviewStatusKeys: [
        { text: this.$t('deconve.allFemale'), value: NOTIFICATION_REVIEW_STATUS.all },
        { text: this.$t('deconve.truePositive'), value: NOTIFICATION_REVIEW_STATUS.yes },
        { text: this.$t('deconve.falsePositive'), value: NOTIFICATION_REVIEW_STATUS.no },
        { text: this.$t('deconve.unreviewed'), value: NOTIFICATION_REVIEW_STATUS.unreviewed },
      ],
    };
  },
  computed: {
    ...mapGetters({ }),
    hasFilterChanged() {
      const defaultOption = defaultRelatedNotificationsFilterOptions();

      return Boolean(this.filterOptions.reviewStatus !== NOTIFICATION_REVIEW_STATUS.yes
        || this.filterOptions.createdAfter !== defaultOption.createdAfter
        || this.filterOptions.createdBefore !== defaultOption.createdBefore
        || this.filterOptions.createdDateOption !== defaultOption.createdDateOption
        || this.filterOptions.search !== defaultOption.search);
    },
    hasMoreNotifications() {
      if (this.notifications?.length !== 0) {
        const { has_more: hasMore } = this.notifications;

        return hasMore;
      }

      return false;
    },
    isOnDeconveHubWorkspace() {
      return this.$can('use', 'com.deconve.hub');
    },
    currentNotificationId() {
      return this.$route.params.notificationId;
    },
    search() {
      return this.filterOptions.search;
    },
  },
  watch: {
    search() {
      if (!this.isLoading) {
        this.onFilterChanged();
      }
    },
  },
  created() {
    this.handleFetchNotifications();
  },
  methods: {
    ...mapActions({
      fetchProfileNotifications: 'faceid/fetchProfileNotifications',
    }),
    handleFetchNotifications() {
      let options = {
        ...this.filterOptions,
        page: this.page,
        itemsPerPage: this.itemsPerPage,
      };

      // For shared notification, the superProfileId and personId can be empty
      if (this.isOnDeconveHubWorkspace) {
        if (!this.superProfileId) return;

        options = { ...options, superProfileId: this.superProfileId };
      } else {
        if (!this.personId) return;

        options = { ...options, personId: this.personId };
      }

      this.isLoading = true;

      this.fetchProfileNotifications(options).then((data) => {
        let notificationsItems = this.notifications?.items;

        if (notificationsItems) {
          data.items.forEach((item) => {
            notificationsItems.push(item);
          });
        } else {
          notificationsItems = data.items;
        }

        this.notifications = { ...data, items: notificationsItems };
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    onFilterChanged() {
      this.page = 1;
      this.notifications = [];
      this.handleFetchNotifications();
    },
    clearFilters() {
      this.filterOptions = defaultRelatedNotificationsFilterOptions();
      this.onFilterChanged();
    },
    loadNotifications() {
      if (this.hasMoreNotifications) {
        this.page += 1;
        this.handleFetchNotifications();
      }
    },
    onCreatedAtDateChanged({ startDate, endDate, mode }) {
      this.filterOptions.createdBefore = endDate;
      this.filterOptions.createdAfter = startDate;
      this.filterOptions.createdDateOption = mode;

      this.onFilterChanged();
    },
  },
};
</script>
