// Copyright (C) 2024 Deconve Technology. All rights reserved.

import i18n from '@/plugins/i18n';

interface ProfileDefinition {
  id: string;
    type: string;
    title: string;
    text: string;
    details?: string;
    scopes?: string[];
}

export function getProfiles(): ProfileDefinition[] {
  const profiles: ProfileDefinition[] = [
    {
      id: 'admin',
      type: 'admin',
      title: i18n.t('deconve.usersDetails.admin.title') as string,
      text: i18n.t('deconve.usersDetails.admin.text') as string,
      details: i18n.t('deconve.usersDetails.admin.details') as string,
      scopes: [],
    },
    {
      id: 'operator',
      type: 'operator',
      title: i18n.t('deconve.usersDetails.operator.title') as string,
      text: i18n.t('deconve.usersDetails.operator.text') as string,
      details: i18n.t('deconve.usersDetails.operator.details') as string,
      scopes: [
        'create:com.deconve.faceid.person',
        'read:com.deconve.faceid.person',
        'update:com.deconve.faceid.person',
        'delete:com.deconve.faceid.person',
        'read:com.deconve.faceid.notification',
        'update:com.deconve.faceid.notification',
        'read:com.deconve.faceid.occurrence',
        'update:com.deconve.faceid.occurrence',
        'read:com.deconve.video',
        'read:com.deconve.unit',
      ],
    },
    {
      id: 'monitor',
      type: 'monitor',
      title: i18n.t('deconve.usersDetails.monitor.title') as string,
      text: i18n.t('deconve.usersDetails.monitor.text') as string,
      details: i18n.t('deconve.usersDetails.monitor.details') as string,
      scopes: [
        'read:com.deconve.faceid.notification',
        'update:com.deconve.faceid.notification',
        'read:com.deconve.faceid.occurrence',
        'update:com.deconve.faceid.occurrence',
        'read:com.deconve.video',
        'read:com.deconve.unit',
      ],
    },
    {
      id: 'reviewer',
      type: 'reviewer',
      title: i18n.t('deconve.usersDetails.reviewer.title') as string,
      text: i18n.t('deconve.usersDetails.reviewer.text') as string,
      details: i18n.t('deconve.usersDetails.reviewer.details') as string,
      scopes: [
        'read:com.deconve.faceid.person',
        'read:com.deconve.faceid.notification',
        'update:com.deconve.faceid.notification',
        'read:com.deconve.faceid.occurrence',
        'update:com.deconve.faceid.occurrence',
        'read:com.deconve.video',
        'read:com.deconve.unit',
      ],
    },
    {
      id: 'custom',
      type: 'custom',
      title: i18n.t('deconve.usersDetails.custom.title') as string,
      text: i18n.t('deconve.usersDetails.custom.text') as string,
    },
  ];

  return profiles;
}

export function getProfile(role: string, scopes: string[]): ProfileDefinition {
  const profiles = getProfiles();

  if (role === 'admin') return profiles.find(({ id }) => id === 'admin') as ProfileDefinition;

  const selectedProfile = profiles.find((profile) => {
    const { scopes: profileScopes } = profile;

    if (profileScopes) {
      const userScopesSet = new Set(scopes);
      const profileScopesSet = new Set(profileScopes);

      const hasSameScope = (
        userScopesSet.size === profileScopesSet.size
        && [...userScopesSet].every((scope) => profileScopesSet.has(scope))
      );

      return hasSameScope;
    }

    return false;
  });

  if (selectedProfile) return selectedProfile;

  return profiles.find(({ id }) => id === 'custom') as ProfileDefinition;
}
