// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';

import {
  SuperProfileFilterOptions, superProfileStatusToBoolean,
  similarProfilesStatusToBoolean, REVIEW_STATUS,
} from '@/utils/faceidFilters';
import {
  SuperProfile, SuperProfileFiltersSettings, SuperProfileImages, SuperProfilesState, types,
} from './types';
import { RootState } from '../../types';
import { getSuperProfileById, getSuperProfileImages00, getSuperProfiles } from './demo';

interface FilterSuperProfilesRequestParams {
  limit: number;
  skip: number;
  search?: string;
  sort_by: string;
  sort_order: string;
  in_trash?: boolean;
  created_after?: string;
  created_before?: string;
  workspace_id?: string;
  unit_id?: string;
  has_similar: boolean;
  status: string | undefined;
}

export const actions: ActionTree<SuperProfilesState, RootState> = {
  fetchSuperProfiles({ commit, state, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!state.superProfileFiltersSettings) {
        resolve(undefined);
        return;
      }

      const {
        page,
        itemsPerPage,
        search,
        sortBy,
        sortOrder,
        inTrash,
        tags,
        createdAfter,
        createdBefore,
        unitId,
        hasSimilar,
        reviewStatus,
      } = state.superProfileFiltersSettings as SuperProfileFiltersSettings;

      const skip = itemsPerPage * (page - 1);

      let url = '/faceid/super-profiles/';

      if (tags) {
        (tags as string[]).forEach((tagId, index) => {
          if (!index) {
            url += `?tag_ids=${tagId}`;
          } else {
            url += `&tag_ids=${tagId}`;
          }
        });
      }

      const status = reviewStatus === REVIEW_STATUS.all ? undefined : reviewStatus;

      const params: FilterSuperProfilesRequestParams = {
        limit: itemsPerPage,
        skip,
        search,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: sortBy,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_order: sortOrder,
        // eslint-disable-next-line @typescript-eslint/camelcase
        in_trash: inTrash,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_after: createdAfter,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_before: createdBefore,
        // eslint-disable-next-line @typescript-eslint/camelcase
        has_similar: hasSimilar,
        status,
      };

      if (unitId) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        params.unit_id = unitId;
      }

      const requestOptions: AxiosRequestConfig = {
        url,
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        params,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      if (rootGetters.isDemoMode) {
        getSuperProfiles().then((data) => {
          commit(types.GET_FACEID_SUPER_PROFILES_PREVIEW_SUCCESS, data);
          resolve();
        });
      } else {
        axios(requestOptions).then((response) => {
          const { data } = response;

          commit(types.GET_FACEID_SUPER_PROFILES_PREVIEW_SUCCESS, data);
          resolve();
        }).catch((error) => reject(error));
      }
    });
  },
  fetchSuperProfileHelper({ rootGetters }, superProfileId): Promise<SuperProfile> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/super-profiles/${superProfileId}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then((response) => {
        const { data } = response as { data: SuperProfile };

        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  fetchSuperProfile({ dispatch, commit, rootGetters }, superProfileId): Promise<SuperProfile> {
    return new Promise((resolve) => {
      commit(types.GET_FACEID_SUPER_PROFILE_REQUEST);

      if (rootGetters.isDemoMode) {
        getSuperProfileById(superProfileId).then((data) => {
          commit(types.GET_FACEID_SUPER_PROFILE_SUCCESS, data);
          resolve(data);
        });
      } else {
        dispatch('fetchSuperProfileHelper', superProfileId).then((superProfileData) => {
          commit(types.GET_FACEID_SUPER_PROFILE_SUCCESS, superProfileData);
          resolve(superProfileData);
        });
      }
    });
  },
  fetchSuperProfileImagesForReviewer(
    { commit, rootGetters }, superProfileId,
  ): Promise<SuperProfileImages> {
    return new Promise((resolve, reject) => {
      commit(types.GET_FACEID_SUPER_PROFILE_IMAGES_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/super-profiles/${superProfileId}/images/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      if (rootGetters.isDemoMode) {
        getSuperProfileImages00().then((data) => {
          commit(types.GET_FACEID_SUPER_PROFILE_IMAGES_SUCCESS, data);
          resolve(data);
        });
      } else {
        axios(requestOptions).then((response) => {
          const { data } = response as { data: SuperProfileImages };

          commit(types.GET_FACEID_SUPER_PROFILE_IMAGES_SUCCESS, data);

          resolve(data);
        }).catch((error) => {
          commit(types.GET_FACEID_SUPER_PROFILE_IMAGES_FAILURE, error);

          reject(error);
        });
      }
    });
  },
  fetchSimilarSuperProfile({ dispatch, commit }, superProfileId): Promise<SuperProfile> {
    return new Promise((resolve) => {
      dispatch('fetchSuperProfileHelper', superProfileId).then((superProfileData) => {
        commit(types.GET_FACEID_SIMILAR_SUPER_PROFILE_SUCCESS, superProfileData);
        resolve(superProfileData);
      });
    });
  },
  setSuperProfileIteratorSettings({ commit }, filterOptions: SuperProfileFilterOptions) {
    const {
      search, page, itemsPerPage, sortOrder, sortBy, status, tags, createdAfter,
      createdBefore, createdDateOption, unitId, unitName, hasSimilar, reviewStatus,
    } = filterOptions;

    const data = {
      page,
      search,
      itemsPerPage,
      sortBy,
      sortOrder,
      inTrash: superProfileStatusToBoolean(status),
      tags,
      createdAfter,
      createdBefore,
      createdDateOption,
      unitId,
      unitName,
      hasSimilar: similarProfilesStatusToBoolean(hasSimilar),
      reviewStatus,
    };

    commit('setSuperProfileIteratorSettings', data);
  },
  editSuperProfile({ commit, rootGetters }, { superProfileId, payload }): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.EDIT_FACEID_SUPER_PROFILE_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'patch',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/super-profiles/${superProfileId}/`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: rootGetters.authorizationToken,
        },
        data: payload,
      };

      axios(requestOptions).then((response) => {
        const { data } = response as { data: SuperProfile };

        commit(types.EDIT_FACEID_SUPER_PROFILE_SUCCESS, data);
        resolve();
      }).catch((error) => {
        commit(types.EDIT_FACEID_SUPER_PROFILE_FAILURE, error);
        reject(error);
      });
    });
  },
  resetSuperProfileError({ commit }) {
    commit('resetError');
  },
  reviewSuperProfileSharing({ commit, rootGetters },
    { superProfileId, sharingOptions }): Promise<void> {
    return new Promise((resolve, reject) => {
      const payload = {
        ...sharingOptions,
        about: sharingOptions.status === 'approved' ? '' : sharingOptions.about,
      };

      commit(types.EDIT_FACEID_SUPER_PROFILE_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'patch',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/super-profiles/${superProfileId}/actions/sharing/`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: rootGetters.authorizationToken,
        },
        data: payload,
      };

      axios(requestOptions).then((response) => {
        const { data } = response as { data: SuperProfile };

        commit(types.EDIT_FACEID_SUPER_PROFILE_SUCCESS, data);
        resolve();
      }).catch((error) => {
        commit(types.EDIT_FACEID_SUPER_PROFILE_FAILURE, error);
        reject(error);
      });
    });
  },
};

export default actions;
