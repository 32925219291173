// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_LOGIN: 'AUTH_LOGIN',
  SET_UPDATE_TOKEN_TIMER_ID: 'SET_UPDATE_TOKEN_TIMER_ID',

  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

  GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
  GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',

  GET_USER_INTROSPECT_SUCCESS: 'GET_USER_INTROSPECT_SUCCESS',
  ENABLE_DEMO_MODE: 'ENABLE_DEMO_MODE',
};

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profileImage?: string;
}

export interface Introspect {
  organizationId: string;
  role: string;
  scopes: string[];
  workspace_id: string;
  user_id: string;
}

export interface AuthState {
  isLogged: boolean;
  isLoading: boolean;
  isLogging: boolean;
  isDemoMode: boolean;
  authorizationToken?: string;
  authorizationVersion?: string;
  tokenUpdateTimerId?: number;
  user?: User;
  introspect?: Introspect;
  error?: AxiosError;
}
