<template>
  <v-container
    class="pa-2"
    style="overflow-y: auto; height: 100%; width: 100%;"
    fluid
  >
    <v-card
      v-if="expireAt"
      id="superProfileContainer"
      color="warn lighten-4"
      tile
      class="d-flex align-center"
      elevation="0"
      data-cy="person-viewer-trash-message"
      min-height="68"
    >
      <v-card-text
        class="d-flex align-center justify-center"
      >
        <v-icon
          class="mr-2"
          color="warn"
        >
          {{ icons.alertCircleOutline }}
        </v-icon>
        <span class="warn--text text-body-2 text-sm-body-1">
          {{
            $t(
              'deconve.person.personInTrashMessage',
              [$moment(expireAt).format('DD/MM/YYYY HH:mm:ss')]
            )
          }}h
        </span>
      </v-card-text>
    </v-card>

    <v-btn
      left
      absolute
      elevation="0"
      icon
      color="neutral"
      @click="goBack"
    >
      <v-icon>
        {{ icons.chevronLeft }}
      </v-icon>
    </v-btn>

    <v-container
      fluid
      style="max-width: 1200px"
      :class="$vuetify.breakpoint.md ? 'mt-8': 'mt-2'"
    >
      <v-col
        class="mb-4"
      >
        <v-row>
          <div
            class="d-flex justify-center align-center"
            style="flex: 1"
          >
            <v-avatar
              class="d-flex grey lighten-2"
              :size="avatarSize"
            >
              <v-img
                data-cy="profile-image"
                :src="profileImage"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      size="24"
                      indeterminate
                      color="grey lighten-1"
                    />
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </div>
          <v-col style="min-width: 75%">
            <v-row class="d-flex justify-space-between mt-0 mt-sm-4">
              <div
                v-if="name"
                class="d-flex text-h6 text-sm-h5 font-weight-bold textBlack--text"
                style="word-break: break-word; flex: 1"
                data-cy="person-viewer-name"
              >
                {{ name }}
              </div>
              <v-skeleton-loader
                v-else
                style="flex: 1"
                type="heading"
              />
              <square-button
                data-cy="person-viewer-settings"
                :icon-name="icons.dotsHorizontal"
                icon-color="neutral"
                color="transparentBackground"
                outlined
              >
                <v-list
                  dense
                  elevation="0"
                >
                  <v-list-item
                    v-if="expireAt"
                    data-cy="person-viewer-restore-from-trash"
                    :disabled="!$can('update', 'com.deconve.faceid.person')"
                    @click="restoreSuperProfileFromTrash"
                  >
                    {{ $t('deconve.restoreFromTrash') }}
                  </v-list-item>
                  <v-list-item
                    v-else
                    data-cy="person-viewer-move-to-trash"
                    :disabled="!$can('update', 'com.deconve.faceid.person')"
                    @click="moveSuperProfileToTrash"
                  >
                    {{ $t('deconve.moveToTrash') }}
                  </v-list-item>
                </v-list>
              </square-button>
            </v-row>

            <v-row>
              <div
                v-if="createdAt"
                class="
                    d-flex
                    align-center
                    neutral--text
                    font-weight-medium
                    text-body-sm-1
                    text-body-2
                    mr-2
                  "
              >
                <v-icon
                  class="mr-1"
                  color="neutral"
                  size="20"
                >
                  {{ icons.calendarRange }}
                </v-icon>
                {{ createdAt | moment('DD/MM/YYYY') }}
              </div>
              <v-skeleton-loader
                v-else
                class="mr-2"
                type="text"
                style="flex: 1"
                max-width="100px"
              />
              <div
                v-if="updatedAt"
                class="
                    d-flex
                    align-center
                    neutral--text
                    font-weight-medium
                    text-body-sm-1
                    text-body-2
                    mr-4
                  "
              >
                <v-icon
                  class="mr-1"
                  color="neutral"
                  size="20"
                >
                  {{ icons.pencilOutline }}
                </v-icon>
                {{ updatedAt | moment('DD/MM/YYYY') }}
              </div>
              <v-skeleton-loader
                v-else
                type="text"
                style="flex: 1"
                max-width="100px"
              />
            </v-row>

            <v-row>
              <div class="d-flex flex-wrap">
                <tag
                  v-for="(tag, idx) in tags"
                  :key="`${tag.id}-${idx}`"
                  class="pa-1"
                  :tag-id="tag.id"
                />
              </div>
            </v-row>

            <v-row
              data-cy="person-viewer-about"
              class="mt-6 neutral--text body-2 text-sm-body-1"
              style="word-break: break-word"
            >
              <div v-if="superProfile">
                {{ about || $t('deconve.person.noDescription') }}
              </div>
              <v-skeleton-loader
                v-else
                class="mt-6"
                type="paragraph"
                style="flex: 1"
              />
            </v-row>

            <v-row
              class="mt-6 neutral--text body-2 text-sm-body-1"
            >
              <span>
                <strong>{{ $t('deconve.sharing') }}: </strong>
                <strong :class="superProfileStatusClass">{{ superProfileStatusTranslated }}</strong>
              </span>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-tabs
            v-model="tab"
            grow
            centered
            style="border-bottom: 1px solid"
            :style="{ borderBottomColor: $vuetify.theme.themes.light.border }"
          >
            <v-tabs-slider color="primary" />

            <!-- tab for images -->
            <v-tab class="custom-transform-class text-none">
              <v-icon
                size="24"
                class="mr-2"
              >
                {{ icons.imageMultipleOutline }}
              </v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.images') }}
              </span>
            </v-tab>

            <!-- tab for similar super profiles -->
            <v-tab class="custom-transform-class text-none">
              <v-icon
                class="mr-2"
                size="24"
              >
                {{ icons.similarSuperProfiles }}
              </v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.similarSuperProfiles') }}
              </span>
            </v-tab>

            <!-- tab for monitoring units -->
            <v-tab class="custom-transform-class text-none">
              <v-icon
                class="mr-2"
                size="24"
              >
                {{ icons.monitoringUnits }}
              </v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.units') }}
              </span>
            </v-tab>

            <!-- tab for history -->
            <v-tab class="custom-transform-class text-none">
              <v-icon
                class="mr-2"
                size="24"
              >
                {{ icons.history }}
              </v-icon>
              <span v-if="!$vuetify.breakpoint.mobile">
                {{ $t('deconve.history') }}
              </span>
            </v-tab>
          </v-tabs>

          <v-tabs-items
            v-model="tab"
            style="width: 100%"
          >
            <v-tab-item style="width: 100%">
              <super-profile-images :is-loading="isLoading" />
            </v-tab-item>
            <v-tab-item style="width: 100%">
              <similar-super-profiles-list
                :is-loading="isLoading"
                :similar-super-profiles-list="similarSuperProfiles"
                :super-profile-id="superProfileId"
                @edited="onSuperProfileEdited"
              />
            </v-tab-item>
            <v-tab-item>
              <profile-units
                :is-loading="isLoading"
                :profile-id="superProfileId"
                :is-person-request="false"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-col>

      <v-speed-dial
        v-model="isToShowButtonOptions"
        fixed
        bottom
        right
        direction="top"
        transition="slide-y-reverse-transition"
      >
        <template
          v-slot:activator
        >
          <v-btn
            fab
            color="primary"
            bottom
            right
          >
            <v-icon>{{ buttonIconSelection }}</v-icon>
          </v-btn>
        </template>

        <v-btn
          fab
          small
          color="primary"
          @click="openPersonShareDialog"
        >
          <v-icon>{{ icons.messageDraw }}</v-icon>
        </v-btn>

        <v-btn
          fab
          small
          color="primary"
          :disabled="!$can('update', 'com.deconve.faceid.person') || !isEditEnabled"
          @click="$emit('onEditButtonClicked')"
        >
          <v-icon>{{ icons.pencilOutline }}</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-container>
    <super-profile-share-dialog ref="shareDialog" />
  </v-container>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import {
  mdiAlertCircleOutline, mdiChevronLeft, mdiStoreOutline,
  mdiDotsHorizontal, mdiCalendarRange, mdiHistory,
  mdiPencilOutline, mdiImageMultipleOutline,
  mdiClose, mdiMenu, mdiMessageDraw, mdiAccountMultipleOutline,
} from '@mdi/js';

import SquareButton from '@/components/SquareButton.vue';
import Tag from '@/components/Tag.vue';
import { downloadImage } from '@/api';

import SimilarSuperProfilesList from '@/components/SimilarSuperProfilesList.vue';
import SuperProfileShareDialog from '@/components/SuperProfileShareDialog.vue';
import ProfileUnits from '@/components/ProfileUnits.vue';
import SuperProfileImages from './SuperProfileImages.vue';

export default {
  name: 'SuperProfileViewer',
  components: {
    SquareButton,
    Tag,
    SuperProfileImages,
    SuperProfileShareDialog,
    SimilarSuperProfilesList,
    ProfileUnits,
  },
  props: {
    superProfileId: { type: String, required: true },
  },
  data() {
    return {
      tab: 0,
      superProfile: null,
      profileImage: '',
      isToShowButtonOptions: false,
      icons: {
        alertCircleOutline: mdiAlertCircleOutline,
        chevronLeft: mdiChevronLeft,
        dotsHorizontal: mdiDotsHorizontal,
        calendarRange: mdiCalendarRange,
        pencilOutline: mdiPencilOutline,
        imageMultipleOutline: mdiImageMultipleOutline,
        history: mdiHistory,
        close: mdiClose,
        menu: mdiMenu,
        messageDraw: mdiMessageDraw,
        similarSuperProfiles: mdiAccountMultipleOutline,
        monitoringUnits: mdiStoreOutline,
      },
    };
  },
  computed: {
    ...mapGetters({
      superProfileData: 'faceid/superProfileData',
      isLoading: 'faceid/isLoadingSuperProfile',
    }),
    buttonIconSelection() {
      return this.isToShowButtonOptions ? this.icons.close : this.icons.menu;
    },
    superProfileRef() {
      return this.superProfileData;
    },
    name() {
      return this.superProfile?.name || '';
    },
    createdAt() {
      return this.superProfile?.created_at || '';
    },
    updatedAt() {
      return this.superProfile?.updated_at || '';
    },
    expireAt() {
      return this.superProfile?.expire_at || '';
    },
    about() {
      return this.superProfile?.about || '';
    },
    tags() {
      return this.superProfile?.tags || [];
    },
    similarSuperProfiles() {
      return this.superProfile?.similar_super_profiles || [];
    },
    avatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '150px';
        case 'sm': return '190px';
        case 'md': return '200px';
        case 'lg': return '250px';
        case 'xl': return '250px';
        default: return '150px';
      }
    },
    isEditEnabled() {
      return !this.expireAt;
    },
    superProfileStatus() {
      return this.superProfileRef?.sharing_status?.current_review?.status;
    },
    superProfileStatusTranslated() {
      switch (this.superProfileStatus) {
        case 'approved':
          return this.$t('deconve.approved');
        case 'rejected':
          return this.$t('deconve.rejected');
        case 'waiting':
          return this.$t('deconve.awaitingReview');
        default:
          return '';
      }
    },
    superProfileStatusClass() {
      switch (this.superProfileStatus) {
        case 'approved':
          return 'positive--text';
        case 'rejected':
          return 'negative--text';
        case 'waiting':
          return 'warning--text';
        default:
          return '';
      }
    },
  },
  watch: {
    superProfileRef() {
      this.superProfile = this.superProfileRef;
    },
    updatedAt() {
      // Only reload the images if ther person was updated
      this.resetSuperProfileImages();
      this.getImages();
    },
  },
  created() {
    this.fetchSuperProfile(this.superProfileId).then((data) => {
      this.superProfile = data;

      this.resetSuperProfileImages();
      this.getImages();
    });
  },
  methods: {
    ...mapActions({
      fetchSuperProfile: 'faceid/fetchSuperProfile',
      fetchSuperProfileImages: 'faceid/fetchPersonImages',
      resetSuperProfileImages: 'faceid/resetPersonImages',
      editSuperProfile: 'faceid/editSuperProfile',
    }),
    goBack() {
      this.$emit('onGoBackButtonClicked');
    },
    getImages() {
      if (this.superProfile) {
        const { profile_image_url: profileImageUrl } = this.superProfile;

        downloadImage(profileImageUrl).then((image) => {
          this.profileImage = image;
        });

        this.fetchSuperProfileImages({
          images: this.superProfile.images,
          personId: this.superProfileId,
        });
      }
    },
    moveSuperProfileToTrash() {
      const form = new FormData();

      form.append('move_to_trash', true);
      this.editSuperProfile({ superProfileId: this.superProfileId, payload: form });
    },
    restoreSuperProfileFromTrash() {
      const form = new FormData();

      form.append('restore_from_trash', true);
      this.editSuperProfile({ superProfileId: this.superProfileId, payload: form });
    },
    openPersonShareDialog() {
      this.$refs.shareDialog.open({
        superProfileId: this.superProfileId,
      });
    },
    onSuperProfileEdited() {
      this.$emit('edited');
    },
  },
};
</script>

<style scoped>
  #superProfileContainer .v-speed-dial {
    position: absolute;
  }

  #superProfileContainer .v-btn--floating {
    position: relative;
  }
</style>
