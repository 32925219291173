<template>
  <v-col
    class="mt-6"
  >
    <v-row
      v-if="!isLoadingProfile"
      class="d-flex align-center"
    >
      <v-icon
        class="mr-2"
        color="textBlack"
        :size="$vuetify.breakpoint.mobile? 24 : 28"
      >
        {{ icons.accountMultiple }}
      </v-icon>

      <div class="subtitle-1 text-sm-h6 font-weight-bold textBlack--text">
        {{ $tc('deconve.relatedProfilesNumber', personRelatedProfiles.length) }}
      </div>
    </v-row>
    <v-row
      v-else
      class="mb-2"
    >
      <v-skeleton-loader
        type="heading"
        style="flex: 1"
        max-width="500"
      />
    </v-row>
    <v-row>
      <div
        style="width: 100%"
        class="mt-2"
      >
        <profile-list-viewer
          :profiles-to-show="personRelatedProfiles"
          :is-loading="isLoadingProfile"
          is-related-profiles
        />
      </div>
    </v-row>
  </v-col>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mdiAccountMultipleOutline } from '@mdi/js';
import ProfileListViewer from '@/components/ProfileListViewer.vue';

export default {
  name: 'RelatedProfiles',
  components: {
    ProfileListViewer,
  },
  props: {
    isLoadingProfile: { type: Boolean, required: true },
    person: { type: Object, default: undefined },
  },
  data() {
    return {
      icons: {
        accountMultiple: mdiAccountMultipleOutline,
      },
    };
  },
  computed: {
    personRelatedProfiles() {
      return this.person?.related_profiles || [];
    },
  },

};
</script>
