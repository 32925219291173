// Copyright (C) 2024 Deconve Technology. All rights reserved.

export default {
  CREATE_COM_DECONVE_FACEID_PERSON: 'create:com.deconve.faceid.person',
  READ_COM_DECONVE_FACEID_PERSON: 'read:com.deconve.faceid.person',

  READ_COM_DECONVE_FACEID_OCCURRENCE: 'read:com.deconve.faceid.occurrence',

  READ_COM_DECONVE_UNIT: 'read:com.deconve.unit',
};
