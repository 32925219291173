<template>
  <v-container
    class="pa-2"
    style="overflow-y: auto; height: 100%; width: 100%"
    fluid
  >
    <v-btn
      left
      absolute
      elevation="0"
      icon
      color="neutral"
      @click="goBack"
    >
      <v-icon>{{ icons.chevronLeft }}</v-icon>
    </v-btn>

    <v-form
      ref="form"
      v-model="valid"
      :class="$vuetify.breakpoint.mobile ? 'mt-8': 'mt-0'"
    >
      <v-container
        fluid
        style="max-width: 1200px"
      >
        <v-col dense>
          <v-row class="align-center mb-1">
            <v-icon
              class="mr-2"
              color="textBlack"
              :size="$vuetify.breakpoint.mobile? 24 : 28"
            >
              {{ icons.account }}
            </v-icon>
            <div class="subtitle-1 font-weight-bold textBlack--text">
              {{ $t('deconve.details') }}
            </div>
          </v-row>

          <v-row>
            <v-text-field
              v-model="name"
              data-cy="person-field-name"
              :counter="64"
              :rules="nameRules"
              :label="$t('deconve.name')"
              class="body-2 text-sm-body-1"
              required
              outlined
              @click="resetAlert"
            />
          </v-row>

          <v-row>
            <v-textarea
              v-model="about"
              data-cy="person-field-about"
              :counter="maxAboutTextLength"
              :rules="aboutRules"
              :label="$t('deconve.about')"
              class="body-2 text-sm-body-1"
              outlined
              @click="resetAlert"
            />
          </v-row>
        </v-col>

        <v-col>
          <!-- faces -->
          <v-row
            align-content="center"
            justify="space-between"
            style="height: 64px"
          >
            <div class="d-flex align-center">
              <v-icon
                class="mr-2"
                color="neutralPrimary"
                :size="$vuetify.breakpoint.mobile? 16 : 20"
              >
                {{ icons.faceRecognition }}
              </v-icon>
              <div
                class="subtitle-1 font-weight-bold neutralPrimary--text"
              >
                {{ $t('deconve.person.facesOfThisPerson') }}
              </div>
            </div>
          </v-row>

          <face-list
            :faces="faces"
            :is-loading="superProfileImages.length === 0"
            :is-edit-mode="true"
            @onFaceSelected="setCropImage"
          />

          <v-row
            align="center"
            justify="space-between"
            style="height: 64px"
          >
            <div
              class="d-flex align-center"
            >
              <v-icon
                class="mr-2"
                color="neutralPrimary"
                :size="$vuetify.breakpoint.mobile? 20 : 24"
              >
                {{ icons.image }}
              </v-icon>
              <div
                class="subtitle-1 font-weight-bold neutralPrimary--text"
              >
                {{ superProfileImages.length }} {{ $t('deconve.images') }}
              </div>
            </div>
          </v-row>

          <v-row v-if="showImageCropper">
            <image-cropper-editor
              :key="imageCropperKey"
              :images="superProfileImages"
              :index="cropIndex"
              :is-edit-mode="true"
              :is-super-profile="true"
              @close="onCloseCropper"
              @selectFace="handleSelectFace"
              @detectFaces="handleDetectFaces"
              @deleteFaces="handleDeleteFaces"
              @changed="updateCropIndex"
            />
          </v-row>

          <v-row
            v-else
            justify="center"
          >
            <v-avatar
              v-if="profileImage"
              class="profile mb-6"
              color="grey"
              :size="avatarSize"
            >
              <v-img
                data-cy="profile-image-cropper"
                :src="profileImage"
              />
            </v-avatar>
          </v-row>

          <image-list
            :images="superProfileImages"
            :is-edit-mode="true"
            :is-loading="superProfileImages.length === 0"
            :score-status="true"
            :is-super-profile="true"
            @set-crop-image="setCropImage"
          />
        </v-col>

        <v-col class="mt-4">
          <v-row class="d-flex justify-end">
            <rectangle-button
              data-cy="person-button-cancel"
              color="warn"
              outlined
              class="ml-2"
              @clicked="cancelEdit"
            >
              {{ $t('deconve.cancel') }}
            </rectangle-button>
            <rectangle-button
              :is-loading="isSendingProfile"
              data-cy="person-button-submit"
              color="primary"
              class="ml-2"
              :disabled="(!valid)"
              @clicked="validationToSubmit"
            >
              {{ $t('deconve.submit') }}
            </rectangle-button>
          </v-row>
        </v-col>

        <div v-if="alert && !isLoading && alertMessageText">
          <v-snackbar
            v-model="alert"
            data-cy="snackbar"
            :color="getAlertColor"
          >
            {{ alertMessageText }}

            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="resetAlert"
              >
                <v-icon color="white">
                  {{ icons.close }}
                </v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';

import ImageList from '@/components/ImageList.vue';
import ImageCropperEditor from '@/components/ImageCropperEditor.vue';
import RectangleButton from '@/components/RectangleButton.vue';
import FaceList from '@/components/FaceList.vue';

import {
  mdiChevronLeft, mdiAccountOutline, mdiFaceRecognition,
  mdiImageOutline, mdiClose,
} from '@mdi/js';

import { dataUrlToBlob } from '../../../utils/data';

export default {
  name: 'SuperProfileEdit',
  components: {
    ImageCropperEditor,
    RectangleButton,
    FaceList,
    ImageList,
  },
  props: {
    superProfileId: { type: String, default: '' },
    modeRegister: { type: Boolean, default: false },
  },
  data() {
    return {
      showImageCropper: false,
      valid: true,
      name: '',
      about: '',
      createdAt: '',
      updatedAt: '',
      alertMessage: '',
      alertColor: '',
      cropIndex: 0,
      maxAboutTextLength: 356,
      imageCropperKey: 1,
      alert: false,
      wasImageEdited: false,
      isSendingProfile: false,
      icons: {
        chevronLeft: mdiChevronLeft,
        account: mdiAccountOutline,
        faceRecognition: mdiFaceRecognition,
        image: mdiImageOutline,
        close: mdiClose,
      },
    };
  },
  computed: {
    ...mapGetters({
      getSuperProfile: 'faceid/getSuperProfile',
      superProfileImages: 'faceid/personImages',
      profileImage: 'faceid/getPersonProfileImage',
      isLoading: 'faceid/isLoadingSuperProfile',
      errorMessage: 'faceid/editSuperProfileError',
    }),
    superProfileDataRef() {
      return this.getSuperProfile(this.superProfileId);
    },
    faces() {
      const selectedFaces = [];

      this.superProfileImages.forEach((img, i) => {
        if (img.info?.faces.length > 0) {
          selectedFaces.push({
            image: img.originalImage || img.mediumImage || img.thumbnailImage,
            name: img.originalName || img.mediumName,
            info: img.info,
            originalHeight: img.originalHeight,
            originalWidth: img.originalWidth,
            index: i,
          });
        }
      });

      return selectedFaces;
    },
    getAlertColor() {
      if (this.alertColor) {
        return this.alertColor;
      }

      return this.errorMessage ? 'warn' : 'primary';
    },
    translatedErrorMessage() {
      if (this.errorMessage) {
        return this.$t('deconve.error.unknown');
      }

      return null;
    },
    alertMessageText() {
      return this.translatedErrorMessage || this.alertMessage;
    },
    nameRules() {
      return [
        (v) => !!v || this.$t('deconve.inputRules.name.required'),
        (v) => (v && v.length <= 64) || this.$t('deconve.inputRules.name.limit'),
      ];
    },
    aboutRules() {
      return [
        (v) => (!v || (v && v.length <= this.maxAboutTextLength))
          || this.$t('deconve.inputRules.about', [this.maxAboutTextLength]),
      ];
    },
    avatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '150px';
        case 'sm': return '190px';
        case 'md': return '200px';
        case 'lg': return '250px';
        case 'xl': return '250px';
        default: return '150px';
      }
    },
  },
  watch: {
    superProfileDataRef() {
      this.initEdit();
    },
  },
  created() {
    this.initEdit();
  },
  methods: {
    ...mapActions({
      fetchSuperProfile: 'faceid/fetchSuperProfile',
      editSuperProfile: 'faceid/editSuperProfile',
      fetchPersonImages: 'faceid/fetchPersonImages',
      fetchPersonProfileImage: 'faceid/fetchPersonProfileImage',
      getOriginalPersonImage: 'faceid/getOriginalPersonImage',
      deleteFaces: 'faceid/deleteFaces',
      detectFaces: 'faceid/detectFaces',
      selectFace: 'faceid/selectFace',
      resetSuperProfileError: 'faceid/resetSuperProfileError',
    }),
    resetAlert() {
      this.alert = false;
      this.alertColor = '';
      this.alertMessage = '';
    },
    showAlert(color, message) {
      this.alertColor = color;
      this.alertMessage = message;
      this.alert = true;
      this.isSendingProfile = false;
    },
    handleDeleteFaces(index) {
      this.deleteFaces(index);
      this.wasImageEdited = true;
    },
    handleDetectFaces(index) {
      this.detectFaces(index);
      this.wasImageEdited = true;
    },
    handleSelectFace(index) {
      this.selectFace(index);
      this.wasImageEdited = true;
    },
    onCloseCropper() {
      this.showImageCropper = false;
      this.imageCropperKey += 1;
    },
    setCropImage(index) {
      this.cropIndex = index;
      this.showImageCropper = true;
    },
    updateCropIndex(index) {
      this.cropIndex = index;
    },
    goBack() {
      this.$emit('onGoBackButtonClicked');
    },
    hasNoFaceDetected() {
      const imageIndex = this.superProfileImages.findIndex(
        (image) => image.info && image.info.faces.length > 0,
      );

      return imageIndex === -1;
    },
    hasImageWithUnselectedFace() {
      const imageIndex = this.superProfileImages.findIndex(
        (image) => image.info && image.info.faces.length > 0 && image.info.selected === undefined,
      );

      return imageIndex >= 0;
    },
    validationToSubmit() {
      this.resetAlert();
      this.resetSuperProfileError();

      this.isSendingProfile = true;

      if (this.hasNoFaceDetected()) {
        this.showAlert('warn', this.$t('deconve.person.alerts.validationImage.noFaceDetected'));
        return;
      }

      if (this.hasImageWithUnselectedFace()) {
        this.showAlert('warn', this.$t('deconve.person.alerts.validationImage.noFaceSelected'));
        return;
      }

      this.submit();
    },
    prepareImagesToSubmit() {
      return new Promise((resolve) => {
        if (!this.wasImageEdited) {
          resolve([]);
          return;
        }

        const getOriginalImagePromises = [];

        this.superProfileImages.forEach((img, index) => {
          if (img.originalImage === '') {
            getOriginalImagePromises.push(this.getOriginalPersonImage(index));
          }
        });

        Promise.all(getOriginalImagePromises).then(() => {
          const imageToBlobPromises = [];

          this.superProfileImages.forEach((img) => {
            imageToBlobPromises.push(
              dataUrlToBlob(img.originalImage).then((blob) => ({
                id: img.id,
                name: img.originalName,
                image: blob,
                info: img.info,
              })),
            );
          });

          Promise.all(imageToBlobPromises).then((files) => resolve(files));
        });
      });
    },
    submit() {
      this.isSendingProfile = true;
      const formData = new FormData();

      this.prepareImagesToSubmit().then(
        (images) => {
          images.forEach((imageFileInfo) => {
            if (imageFileInfo.info && imageFileInfo.info.selected >= 0) {
              const faceInfo = JSON.stringify({
                id: imageFileInfo.id,
                image: imageFileInfo.name,
                faces: [imageFileInfo.info.faces[imageFileInfo.info.selected]],
              });

              formData.append('image_face_infos', faceInfo);
            }
          });

          formData.append('name', this.name);
          formData.append('about', this.about || '');

          this.alert = true;

          this.editSuperProfile({ superProfileId: this.superProfileId, payload: formData })
            .then(() => {
              this.isSendingProfile = false;
              this.fetchSuperProfile(this.superProfileId).then(() => {
                this.$emit('edited');
              });
            }).catch((error) => {
              this.error = error;
              this.isSendingProfile = false;
            });
        },
      );
    },
    cancelEdit() {
      this.$emit('canceled');
    },
    initEdit() {
      const superProfile = this.getSuperProfile(this.superProfileId);

      const {
        name,
        about,
        created_at: createdAt,
        updated_at: updatedAt,
      } = superProfile;

      this.name = name || '';
      this.about = about || '';
      this.createdAt = createdAt || '';
      this.updatedAt = updatedAt || '';

      const { profile_image_url: profileImageUrl } = superProfile;

      this.fetchPersonProfileImage({ profileImageUrl, personId: this.superProfileId });

      this.fetchPersonImages({
        images: superProfile.images,
        personId: this.superProfileId,
      });
    },
  },
};
</script>
