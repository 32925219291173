<template>
  <v-card
    class="d-flex flex-column"
    outlined
    :elevation="isSelected ? 4 : 0"
    hover
    min-width="200"
    style="flex: 1; height: fit-content; width: 100%"
    :loading="isLoadingPreview"
    @click="seeNotification"
  >
    <div class="d-flex flex-column ma-4">
      <v-row
        v-if="enableSelectNotification"
        justify="end"
        class="mb-2"
      >
        <v-checkbox
          v-model="selected"
          class="my-1"
          hide-details
          @click.stop="onSelectButtonClicked"
        />
      </v-row>

      <div
        v-if="!isLoadingPreview"
        class="d-flex mx-0"
        style="overflow: hidden; border-radius: 4px; min-height: 128px"
      >
        <image-container
          width="100%"
          hide-loading-status
          contain
          :loading="!isNotificationImageLoaded"
          :src="notificationImage"
        />
      </div>

      <v-skeleton-loader
        v-else
        type="image"
        width="100%"
        height="128px"
      />

      <div
        v-if="!isLoadingPreview"
        class="d-flex flex-column mt-2"
        style="flex: 0"
      >
        <div
          v-if="isToShowSensitiveInformation"
          class="d-flex flex-column mb-2"
        >
          <span
            style="line-break: anywhere; flex-wrap: nowrap"
            class="text-truncate caption text-sm-body-2 neutral--text"
          >
            <v-icon
              class="mr-1"
              color="neutral"
              :size="$vuetify.breakpoint.mobile? 16: 20"
            >
              {{ icons.playBox }}
            </v-icon>
            {{ videoName }}
          </span>
        </div>
        <div
          :class="{ 'mt-2': isUserANotificationReviewer }"
          class="d-flex align-start justify-space-between"
        >
          <span class="caption text-sm-body-2 neutral--text">
            <v-icon
              class="mr-1"
              color="neutral"
              :size="$vuetify.breakpoint.mobile? 16: 20"
            >
              {{ icons.calendar }}
            </v-icon>
            {{ createdAt }}
          </span>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="reviewStatusColor"
                size="20"
                v-bind="attrs"
                v-on="on"
              >
                {{ reviewStatusIcon }}
              </v-icon>
            </template>
            <span>{{ reviewStatusText }}</span>
          </v-tooltip>
        </div>
      </div>

      <div
        v-else
        class="d-flex flex-column mt-2"
        style="flex: 0"
      >
        <v-skeleton-loader
          type="text"
          width="50%"
          class="mb-2"
        />
        <v-skeleton-loader
          width="70%"
          type="text"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import {
  mdiAlertCircle as reviewStatusUnreviewedIcon,
  mdiCheckCircle as reviewStatusYesIcon,
  mdiCloseCircle as reviewStatusNoIcon,
  mdiPlayBoxMultipleOutline, mdiCalendarRange,
} from '@mdi/js';

import ImageContainer from '@/components/ImageContainer.vue';

import { downloadImage } from '@/api';
import { PERSON_NOTIFICATION } from '@/utils/faceidNotifications';

export default {
  name: 'NotificationImagePreview',
  components: {
    ImageContainer,
  },
  props: {
    id: { type: String, required: true },
    enableSelectNotification: { type: Boolean, required: false, default: false },
    isSelected: { type: Boolean, required: false, default: false },
    data: { type: Object, required: true },
  },
  data() {
    return {
      person: undefined,
      tags: [],
      absolute: true,
      isProfileImageLoaded: false,
      isNotificationImageLoaded: false,
      isSamePersonTypes: {
        yes: 'yes',
        no: 'no',
        unreviewed: 'unreviewed',
      },
      notificationImage: undefined,
      profileImage: undefined,
      selected: false,
      icons: {
        playBox: mdiPlayBoxMultipleOutline,
        calendar: mdiCalendarRange,
      },
    };
  },
  computed: {
    ...mapGetters({
      getNotification: 'faceid/getNotification',
      getPerson: 'faceid/getPerson',
      getVideo: 'faceid/getVideo',
    }),
    isOnDeconveHubWorkspace() {
      return this.$can('use', 'com.deconve.hub');
    },
    isUserANotificationReviewer() {
      const canReadSuperProfileImages = this.$can('read', 'com.deconve.faceid.superprofile.image');
      const canReadSuperProfileDetails = this.$can('read', 'com.deconve.faceid.superprofile');

      return canReadSuperProfileImages && !canReadSuperProfileDetails;
    },
    isToShowSensitiveInformation() {
      return !this.isUserANotificationReviewer;
    },
    video() {
      return this.getVideo(this.data.video.id);
    },
    notification() {
      return this.getNotification(this.id);
    },
    videoName() {
      return this.video?.name || '';
    },
    createdAt() {
      if (this.notification) {
        return this.$moment(this.notification.created_at).format('DD/MM/YYYY HH:mm:ss');
      }

      return '';
    },
    reviewStatusColor() {
      switch (this.notification?.is_same_person) {
        case this.isSamePersonTypes.yes:
          return 'positive';
        case this.isSamePersonTypes.no:
          return 'negative';
        case this.isSamePersonTypes.unreviewed:
          return 'unreviewed';
        default:
          return '';
      }
    },
    reviewStatusText() {
      switch (this.notification?.is_same_person) {
        case this.isSamePersonTypes.yes:
          return this.$t('deconve.notification.isSamePersonTypes.positive');
        case this.isSamePersonTypes.no:
          return this.$t('deconve.notification.isSamePersonTypes.negative');
        case this.isSamePersonTypes.unreviewed:
          return this.$t('deconve.notification.isSamePersonTypes.unreviewed');
        default:
          return '';
      }
    },
    reviewStatusIcon() {
      switch (this.notification?.is_same_person) {
        case this.isSamePersonTypes.yes:
          return reviewStatusYesIcon;
        case this.isSamePersonTypes.no:
          return reviewStatusNoIcon;
        case this.isSamePersonTypes.unreviewed:
          return reviewStatusUnreviewedIcon;
        default:
          return '';
      }
    },
    isASharedPersonProfile() {
      if (this.notification) {
        const { is_from_a_shared_person: sharedPerson } = this.notification;

        return !this.$can('use', 'com.deconve.hub') && sharedPerson;
      }

      return false;
    },
    personDisplayStatus() {
      if (this.notification) {
        const { is_from_a_shared_person: isFromASharedPerson } = this.notification;

        if (this.person) {
          return PERSON_NOTIFICATION.isToShowPersonProfile;
        }

        if (isFromASharedPerson && !this.isOnDeconveHubWorkspace) {
          return PERSON_NOTIFICATION.isNotToShowPersonProfile;
        }

        return PERSON_NOTIFICATION.isAInvalidPersonProfile;
      }

      return PERSON_NOTIFICATION.isAInvalidPersonProfile;
    },
    isToShowPersonProfile() {
      return this.personDisplayStatus === PERSON_NOTIFICATION.isToShowPersonProfile;
    },
    isNotToShowPersonProfile() {
      return this.personDisplayStatus === PERSON_NOTIFICATION.isNotToShowPersonProfile;
    },
    isLoadingPreview() {
      return !this.isNotificationImageLoaded;
    },
  },
  watch: {
    isSelected() {
      this.selected = this.isSelected;
    },
  },
  created() {
    this.selected = this.isSelected;

    this.fetchNotification();
    this.fetchVideo(this.data.video.id).catch(() => {
      // nothing todo
    });
  },
  methods: {
    ...mapActions({
      fetchNotificationData: 'faceid/fetchNotification',
      fetchVideo: 'faceid/fetchVideo',
    }),
    seeNotification() {
      this.$router.push({ name: 'faceid-notification', params: { notificationId: this.id } });
    },
    downloadNotificationImage() {
      if (this.notification) {
        // The image database saves the best original image/face and sends them to the image
        // compressor. So, the notification can be loaded here before the image thumbnail to be
        // saved. Here we check if the thumbnail url is set or if the original face image url is
        // available.
        const { faces, best_face_index: bestFaceIndex } = this.notification;

        const { video_frame: videoFrameInfo } = faces[bestFaceIndex];
        const {
          original_url: originalUrl,
          medium_quality_url: mediumQualityUrl,
          thumbnail_url: thumbnailUrl,
        } = videoFrameInfo;

        const mediumQualityImageUrl = mediumQualityUrl || originalUrl;
        const thumbnailImageUrl = thumbnailUrl || mediumQualityImageUrl;

        downloadImage(thumbnailImageUrl).then((thumbnailImage) => {
          this.notificationImage = thumbnailImage;
          this.isNotificationImageLoaded = true;

          if (mediumQualityImageUrl !== thumbnailImageUrl) {
            downloadImage(mediumQualityImageUrl).then((mediumQualityImage) => {
              this.notificationImage = mediumQualityImage;
            });
          }
        });
      } else {
        this.notificationImage = '';
        this.isNotificationImageLoaded = true;
      }
    },
    onSelectButtonClicked() {
      const data = { notificationId: this.id, isSelected: !this.isSelected };

      this.$emit('onSelectButtonClicked', data);
    },
    fetchNotification() {
      this.fetchNotificationData(this.id).then(() => {
        this.downloadNotificationImage();
      }).catch(() => {
        this.downloadNotificationImage();
      });
    },
  },
};
</script>
